import React, { useEffect } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { history } from '../_helpers';
import { alertActions } from '../_actions';
import { Header } from '../_components';
import { PrivateRoute } from '../_components';
import { HomePage } from '../HomePage';
import { LoginPage } from '../LoginPage';

import { TermsPage } from '../TermsPage';


import { WellUsPage } from '../WellUsPage';


import { ForgotPass } from '../ForgotPass';

import { RegisterPage } from '../RegisterPage';
import { EmailConfPage } from '../EmailConfPage';
import { Assistance } from '../Assistance';

import { AllertModal } from '../_components';

/**
 * 
 */
function App() {
    const alert = useSelector(state => state.alert);
    const dispatch = useDispatch();

    const allertMessagr = useSelector(state => state.alert.message);



    useEffect(() => {
        history.listen((location, action) => {
            // clear alert on location change
            dispatch(alertActions.clear());
        });

        /*
        if (allertMessagr) {
            setTimeout(() => {
                dispatch(alertActions.clear());
            }, 2000);       
        }
        */

    }, [allertMessagr]);

    return (
        <>
            {/*
            <Header />
            */}

            {/*
                alert.message &&
                <div className={`alert ${alert.type}`} style={{ marginTop: '85px' }}>{alert.message}</div>
            */}

            {
                alert.message &&
                    <AllertModal alert={alert} />
            }            

            {/*
                <Route path="/register" component={RegisterPage} />
                <Route path="/well_us" component={WellUsPage} />
            */}
            <Router history={history}>
                <Switch>
                    <PrivateRoute exact path="/" component={HomePage} />
                    <Route path="/confirmation" component={EmailConfPage} />
                    
                    <PrivateRoute path="/register" component={RegisterPage} />
                    <PrivateRoute path="/well_us" component={WellUsPage} />
                    
                    <Route path="/terms" component={TermsPage} />

                    <Route path="/forgot_password" component={ForgotPass} />

                    <Route path="/login" component={LoginPage} />
                    <Route path="/assistance" component={Assistance} />
                    <Redirect from="*" to="/" />
                </Switch>
            </Router>
        </>
    );
}

export { App };

/*
        <div className="jumbotron">
            <div className="container">
                <div className="col-md-8 offset-md-2">
                    {alert.message &&
                        <div className={`alert ${alert.type}`}>{alert.message}</div>
                    }
                    <img src="./logo192.png"></img>
                    <Router history={history}>
                        <Switch>
                            <PrivateRoute exact path="/" component={HomePage} />
                            <Route path="/login" component={LoginPage} />
                            <Route path="/register" component={RegisterPage} />
                            <Route path="/confirmation" component={EmailConfPage} />
                            <Redirect from="*" to="/" />
                        </Switch>
                    </Router>
                </div>
            </div>
        </div>
*/