// import config from 'config';
import { authHeader } from '../_helpers';

let config = {};
// config.apiUrl = 'http://localhost:3000/'
config.apiUrl = 'https://apidev.happio.tech/api/v1'


export const userService = {
    checkOrgCode,
    checkOrgEmail,
    login,
    googleLogin,
    logout,
    emailConfirmation,
    takeAssistance,
    register,
    forgotPassword,
    getAll,
    getById,
    update,
    delete: _delete
};


/**
 * https://apidev.happio.tech/api/v1/auth/is_code_valid
 * 
 * @param {*} orgCode 
 */
function checkOrgCode(orgCode) {

    let sentInformation = {
        "code": orgCode
      }

    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json' 
        },
        referrerPolicy: "no-referrer", // no-referrer, origin, same-origin...
        mode: "cors",
        body: JSON.stringify(sentInformation)
    };

    return fetch(`https://apidev.happio.tech/api/v1/auth/is_code_valid`, requestOptions).then(handleResponse);
}



/**
 * https://apidev.happio.tech/api/v1/auth/is_email_avaliable
 * 
 * @param {*} email 
 */
function checkOrgEmail(email, orgCode) {

    let sentInformation = {
        "email": email,
        "code": orgCode
    }

    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json' 
        },
        referrerPolicy: "no-referrer", // no-referrer, origin, same-origin...
        mode: "cors",
        body: JSON.stringify(sentInformation)
    };

    return fetch(`https://apidev.happio.tech/api/v1/auth/is_email_avaliable`, requestOptions).then(handleResponse);
}




/**
 * https://apidev.happio.tech/api/v1/auth/email_confirmation_request
 * 
 * @param {*} user 
 */
function emailConfirmation(user) {

    let sentInformation = { 
        // "email": "yuriy@happio.io", 
        "email": user.email, 
        "isRegistration": true,
    }

    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json' 
        },
        referrerPolicy: "no-referrer", // no-referrer, origin, same-origin...
        mode: "cors",
        body: JSON.stringify(sentInformation)
    };

    return fetch(`https://apidev.happio.tech/api/v1/auth/email_confirmation_request`, requestOptions).then(handleResponse);
}





/**
 * 
 * @param {*} user 
 */
function takeAssistance(user) {

    let sentInformation = {
        "email": user.email, 
        "name":  user.name,
        "organization":  user.orgCode,
        "message": user.description
    }

    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json' 
        },
        referrerPolicy: "no-referrer", // no-referrer, origin, same-origin...
        mode: "cors",
        body: JSON.stringify(sentInformation)
    };

    return fetch(`https://apidev.happio.tech/api/v1/assistance/send_help_message`, requestOptions).then(handleResponse);
}






function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };

    return fetch(`https://apidev.happio.tech/api/v1/auth/login`, requestOptions).then(handleResponse);
}



/**
 * 
 * {error: "popup_closed_by_user"}
 * 
 * @param {*} googleAswer 
 */
function googleLogin(googleAswer) {

    console.log('googleAswer - ', googleAswer);
    console.log('googleAswer.uc - ', googleAswer.uc);
    console.log('googleAswer.uc.expires_at - ', googleAswer.uc.expires_at);
    
    console.log('googleAswer.Is.ot - ', googleAswer.Is.ot);

    let testData = {
        "hostedDomain": "string",
        "userID": "string",
        "authentication": {
          "accessTokenExpirationDate": googleAswer.uc.expires_at,
          "refreshToken": googleAswer.uc.access_token,
          "idToken": googleAswer.uc.id_token,
          "clientID": googleAswer.uc.idpId,
          "accessToken": googleAswer.uc.access_token,
          "idTokenExpirationDate": googleAswer.uc.expires_in
        },
        "email": "string",
        "givenName": "string",
        "name": "string",
        "familyName": "string",
        "profile": {}
    }

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        // body: JSON.stringify({ email, password })
        body: JSON.stringify( testData )
    };

    return fetch(`https://apidev.happio.tech/api/v1/auth/`, requestOptions).then(handleResponse);
}


/*

То, что должно быть передано по маршруту ( /auth/google_authorization ) Гугл логин ( authentication - все поля обазятельны ):

{
  "hostedDomain": "string",
  "userID": "string",
  "authentication": {
    "accessTokenExpirationDate": 0,
    "refreshToken": "string",
    "idToken": "string",
    "clientID": "string",
    "accessToken": "string",
    "idTokenExpirationDate": 0
  },
  "email": "string",
  "givenName": "string",
  "name": "string",
  "familyName": "string",
  "profile": {}
}
*/







    // console.log('config - ', config);  // {apiUrl: "http://localhost:4000"}

    /*
    return fetch(`${config.apiUrl}/v1/auth/email_confirmation_request`, requestOptions)
    .then(handleResponse)
    .then(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));
        
        return user;
    });
    */

function forgotPassword(email) {

    console.log();

    let sentInformation = { 
        // "email": "yuriy@happio.io", 
        "email": email, 
        "isRegistration": false,
    }

    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json' 
        },
        referrerPolicy: "no-referrer", // no-referrer, origin, same-origin...
        mode: "cors",
        body: JSON.stringify(sentInformation)
    };

    return fetch(`https://apidev.happio.tech/api/v1/auth/email_confirmation_request`, requestOptions).then(handleResponse);
}
















function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}


/**
 * https://apidev.happio.tech/api/v1/auth/email_confirmation_request
 * 
 * @param {*} user 
 */
function register(user) {

    /*
    let sentInformation = { 
        // "email": "yuriy@happio.io", 
        "email": user.email, 
        "isRegistration": true,
    }
    */
    
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json' 
        },
        referrerPolicy: "no-referrer", // no-referrer, origin, same-origin...
        mode: "cors",
        body: JSON.stringify(user)
    };

    return fetch(`https://apidev.happio.tech/api/v1/auth/registration`, requestOptions).then(handleResponse);
}

/*
https://apidev.happio.tech/api/v1/auth/email_confirmation_request
function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${config.apiUrl}/users/register`, requestOptions).then(handleResponse);
}
*/



function update(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${config.apiUrl}/users/${user.id}`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                // location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}