import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

/**
 * 
 * @param {*} param0 
 */
function PrivateRoute({ component: Component, roles, ...rest }) {

    // const confUser = useSelector(state => state.confirmation.confUser);
    const registering = useSelector(state => state.confirmation.registering);
    const loggedIn = useSelector(state => state.authentication.loggedIn);

    // console.log('loggedIn - ', loggedIn);
    // debugger;

    return (
        <Route {...rest} render={props => {
            if (loggedIn) {
                return <Component {...props} />
            }

            if ( !registering ) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/confirmation', state: { from: props.location } }} />
            }

            // logged in so return component
            return <Component {...props} />
        }} />
    );
}

export { PrivateRoute };