import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../_actions';
import { Header } from '../_components';
import { Footer } from '../_components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const eye = <FontAwesomeIcon icon={faEye} />;


const responseGoogle = (response) => {
    console.log(response);
}


/**
 * 
 */
function Assistance() {

    const [submitted, setSubmitted] = useState(false);
    
    const [isEmailValid, setIsEmailValid] = useState(false);

    const [user, setUser] = useState({
        email: '',
        name: '',
        orgCode: '',
        description: '',
    });

    let { email, name, orgCode, description } = user;
    // console.log('orgCode - ', orgCode);
    
    const dispatch = useDispatch();
    
    /*
function validateEmail(email) 
    {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }
    
console.log(validateEmail('anystring@anystring.anystring'));    
    */

    useEffect(() => {
        if (orgCode.length == 6) {
            // dispatch(userActions.takeAssistance(user));
        }
    }, [user.orgCode]);

    function handleChange(e) {
        const { name, value } = e.target;
        setUser(user => ({ ...user, [name]: value }));

        // debugger;
    }

    function handleSubmit(e) {
        e.preventDefault();

        // const re = /\S+@\S+\.\S+/;
        // re.test(email);

        // debugger;

        setSubmitted(true);
        if ( email && name.length > 1 && description.length > 30 ) {
            
            console.log('take assistanse help');
            dispatch(userActions.takeAssistance(user));

        }
    }

    return (<>

        {/*
        */}
        <Header />

        <section className="wow fadeIn bg-white padding-36px-tb sm-padding-30px-tb page-title-medium margin-80px-top" style={{visibility: 'visible'}}>
            <div className="container">
                <div className="row align-items-center justify-content-center">

                    {/*
                    <div className="col-12 text-center">
                        <h1 className="text-black font-weight-700 text-black d-inline-block">Sign up</h1>
                    </div>
                    */}

                    <form className="margin-30px-bottom form-width" onSubmit={handleSubmit}>
                        <h1 className="text-black font-weight-700 text-black text-center">Assistance</h1>
                        <span className="alt-font font-weight-400 d-block title-astc text-center">
                            Having problems with signing up? Please share the details and we will get back to you via email.
                        </span>
                        
                        {/*
                        <input class="medium-input border-radius-9px margin-10px-bottom input-hp" type="email" name="email" placeholder="Your email" />
                        <input className="medium-input border-radius-9px margin-10px-bottom input-hp" type="text" name="name" placeholder="Your name" />
                        */}

                        <input 
                            placeholder="Your email"
                            type="email" 
                            name="email" 
                            value={email} 
                            onChange={handleChange} 
                            className={'medium-input border-radius-9px margin-10px-bottom input-hp form-control' + (submitted && !email ? ' is-invalid' : '')} 
                        />
                        {submitted && !email &&
                            <div className="invalid-feedback">Please set a valid email</div>
                        }

                        <input 
                            placeholder="Your name"
                            type="text" 
                            name="name" 
                            value={name} 
                            onChange={handleChange} 
                            className={'medium-input border-radius-9px margin-10px-bottom input-hp form-control' + (submitted && name.length < 1 ? ' is-invalid' : '')} 
                        />
                        {submitted && name.length < 1 &&
                            <div className="invalid-feedback">Please enter you name</div>
                        }                     

                        <input 
                            type="text" 
                            name="orgCode"  
                            placeholder="Organization code" 
                            value={orgCode} 
                            onChange={handleChange} 
                            className={'medium-input border-radius-9px margin-10px-bottom input-hp form-control' + (user.orgCode ? ' is-invalid' : '')} 
                        />
                        <p className="subtitle-astc">* For business users only</p>
                        {/*
                        <p className="input-text">Leave empty if not a business account</p>

                            user.orgCode.length == 6 && !checkOrgCodeGlobal
                                ? <div className="invalid-feedback">Organization code is not valid</div>
                                : <p className="input-text">Leave empty if not a business account</p>
                        */}

                        <textarea 
                            placeholder="Detailed description of the issue"
                            rows="6" 
                            name="description"
                            value={description} 
                            onChange={handleChange} 
                            className={'medium-textarea h-144px textarea--astc m-0' + (submitted && description.length < 30 ? ' is-invalid' : '')} 
                        />
                        {submitted && !description.length < 30 &&
                            <div className="invalid-feedback">Please enter detailed description of the issue</div>
                        }  

                        {/*
                        <textarea className="medium-textarea h-144px textarea--astc m-0" rows="6" name="comment" placeholder="Detailed description of the issue"></textarea>
                        */}


                        
                        
                        <button className="btn btn-main btn-green w-100 margin-15px-bottom submit" type="submit" name="submit">Submit</button>


                        <div className="form-results border-radius-5px d-none"></div>
                        <span className="alt-font margin-20px-bottom d-block text-center">
                            {/*
                            <a href="#" target="_blank" class="text-green font-weight-500">
                                Back to Sign in!
                            </a>
                            */}

                            <Link to="/confirmation" className="text-green font-weight-500">Back to Sign in!</Link>

                        </span>
                    </form>
                </div>
            </div>
        </section>
        <Footer />
        </>
    );
}

export { Assistance };



/*
    <input type="password" name="password" value={user.password} onChange={handleChange} className={'form-control' + (submitted && !correctPassword ? ' is-invalid' : '')} />
*/

/*

            <div className="col-lg-8 offset-lg-2">
                <h2>Sign up</h2>
                <form name="form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>First Name</label>
                        <input type="text" name="firstName" value={user.firstName} onChange={handleChange} className={'form-control' + (submitted && !user.firstName ? ' is-invalid' : '')} />
                        {submitted && !user.firstName &&
                            <div className="invalid-feedback">First Name is required</div>
                        }
                    </div>
                    <div className="form-group">
                        <label>Last Name</label>
                        <input type="text" name="lastName" value={user.lastName} onChange={handleChange} className={'form-control' + (submitted && !user.lastName ? ' is-invalid' : '')} />
                        {submitted && !user.lastName &&
                            <div className="invalid-feedback">Last Name is required</div>
                        }
                    </div>

                    <div className="form-group">
                        <label>Organization code</label>
                        <input type="text" name="orgCode" value={user.orgCode} onChange={handleChange} className={'form-control'} />
                        <div className="small-input-feed">Leave empty if note a business account (ZZEV6R) (jenniferhaney@hotmail.com)</div>
                    </div>                    


                    <div className="form-group">
                        <label>Email</label>
                        <input type="text" name="email" value={user.email} onChange={handleChange} onBlur={handleOnBlur} className={'form-control' + (submitted && !user.email ? ' is-invalid' : '')} />
                        {submitted && !user.email &&
                            <div className="invalid-feedback">Email is required</div>
                        }
                    </div>

                    <div className="form-group">
                        <label>Password</label>

                        <div className="pass-wrapper">
                            <input 
                                type={passwordShown ? "text" : "password"} 
                                name="password" 
                                value={user.password} 
                                onChange={handleChange} 
                                className={'form-control' + (submitted && !correctPassword ? ' is-invalid' : '')} 
                                />
                            <i style={{right: '8%'}} onClick={togglePasswordVisiblity}>{eye}</i>{" "}
                        
                        {submitted && !user.password &&
                            <div className="invalid-feedback">Password is required</div>
                        }
                        {submitted && !correctPassword &&
                            <div className="invalid-feedback">Password must contein minimum eight characters, at least one uppercase letter, one lowercase letter and one number</div>
                        }                    
                        </div>
                    </div>
                    <div className="form-group">
                        <button className="btn btn-primary">
                            {registering && <span className="spinner-border spinner-border-sm mr-1"></span>}
                            Sign up
                        </button>
                        <Link to="/login" className="btn btn-link">Cancel</Link>
                    </div>
                </form>
            </div>
            <GoogleLogin
                clientId="827993157011-9nnqufjk99sh2e4dt0l0ui44ro2o2hmi.apps.googleusercontent.com"
                buttonText="Login"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
            />

*/