export const userConstants = {
    CONFIRMATION_REQUEST: 'CONFIRMATION_REGISTER_REQUEST',
    CONFIRMATION_SUCCESS: 'CONFIRMATION_REGISTER_SUCCESS',
    CONFIRMATION_FAILURE: 'CONFIRMATION_REGISTER_FAILURE',

    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',


    ORGCODE_REQUEST: 'ORGCODE_CONFIRMATION_REQUEST',
    ORGCODE_SUCCESS: 'ORGCODE_CONFIRMATION_SUCCESS',
    ORGCODE_FAILURE: 'ORGCODE_CONFIRMATION_FAILURE',


    ORGEMAIL_REQUEST: 'ORGEMAIL_CONFIRMATION_REQUEST',
    ORGEMAIL_SUCCESS: 'ORGEMAIL_CONFIRMATION_SUCCESS',
    ORGEMAIL_FAILURE: 'ORGEMAIL_CONFIRMATION_FAILURE',    


    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',


    ASSISTANCE_REQUEST: 'USERS_ASSISTANCE_REQUEST',
    ASSISTANCE_SUCCESS: 'USERS_ASSISTANCE_SUCCESS',
    ASSISTANCE_FAILURE: 'USERS_ASSISTANCE_FAILURE'        
};
