import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from '../_components';
import { Footer } from '../_components';
import { userActions } from '../_actions';
import GoogleLogin from 'react-google-login';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const eye = <FontAwesomeIcon icon={faEye} />;



/**
 * Инпуты тут заполняются оптимальным путем - [name]: value
 * 
 * Есди проихошла попытка отправки формы - submitted && !username ? ' is-invalid
 * но имени пользователя нет - будет ошибка... (проверка на наличие имени пользователя и пароля
 * проходит буквально везде - выглядит очень удобно)
 * 
 * По событию отправки формы, если у нас ЮзерНейм и Пароль введены мы выполняем:
 *    dispatch(userActions.login(username, password, from));
 *    from - судя по всему передается путь на который потом произойдет Редирект...
 * 
 * 
 * ? Что такое Локейшен - const location = useLocation();
 */
function LoginPage() {
    const dispatch = useDispatch();

    const [inputs, setInputs] = useState({
        email: '',
        password: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const { email, password } = inputs;

    const [isUserTryToSetEmail, setIsUserTryToSetEmail] = useState(false);

    const [correctEmail, setCorrectEmail] = useState(false);

    const [correctPassword, setCorrectPassword] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
    };

    const responseGoogle = (response) => {
        // console.log(response);
        
        dispatch(userActions.googleLogin(response));
        if (response) {
            // console.log('responseGoogle if');
        }
    }

    function checkEmail() {

        let isCorrectEmail = email.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/);
        
        // console.log('isCorrectEmail - ', isCorrectEmail);

        if(isCorrectEmail) {
            setCorrectEmail(true);
        } else {
            setCorrectEmail(false);
        }
    }    

    /*
    const loggingIn = useSelector(state => state.authentication.loggingIn);
    const location = useLocation();
    */

    // reset login status
    useEffect(() => { 
        // dispatch(userActions.logout()); 
    }, []);

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));

        let isUserPasswordRight = password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/);
        if(isUserPasswordRight) {
            setCorrectPassword(true);
        } 
    }

    function handleSubmit(e) {
        e.preventDefault();

        setSubmitted(true);
        if ( email && password && correctPassword && correctEmail ) {
            // get return url from location state or default to home page
            // const { from } = location.state || { from: { pathname: "/" } };
            // dispatch(userActions.login(username, password, from));
            
            console.log('email && password && correctPassword');
            dispatch(userActions.login(email, password));
        }
    }

    return (<>

        <Header />
        <section class="wow fadeIn bg-white padding-36px-tb sm-padding-30px-tb page-title-medium margin-80px-top" style={{visibility: 'visible'}}>
            <div class="container h-650px">
                <div class="row align-items-center justify-content-center">
                    
                    {
                    <div class="col-12 text-center">
                        <h1 class="text-black font-weight-700 text-black d-inline-block margin-30px-bottom">Login</h1>
                    </div>
                    }


                    <form onSubmit={handleSubmit} class="margin-30px-bottom form-width">

                        {/*
                        <input class="medium-input border-radius-9px margin-10px-bottom input-hp" type="email" name="email" placeholder="Your email address" />
                        */}
                        <input 
                            placeholder="Your email address" 
                            type="email" 
                            name="email" 
                            value={email} 
                            onBlur={(e) => {checkEmail(); setIsUserTryToSetEmail(true);}} 
                            onChange={handleChange} 
                            className={'medium-input border-radius-9px margin-10px-bottom input-hp form-control' + (submitted && email.length < 5 ? ' is-invalid' : '')} 
                        />
                        {submitted && email.length < 5 &&
                            <div className="invalid-feedback">Email is required</div>
                        }
                        { isUserTryToSetEmail && !correctEmail &&
                            <div className="invalideTextForCorporativeEmail">Please provide a valid email.</div>
                        }       


                        <div className="pass-wrapper">
                            <input 
                                placeholder="Password"
                                type={passwordShown ? "text" : "password"} 
                                name="password" 
                                onChange={handleChange} 
                                value={password} 
                                className={'medium-input border-radius-9px margin-5px-bottom input-hp form-control' + (submitted && !correctPassword ? ' is-invalid' : '')} 
                            />
                            <i style={{right: '8%'}} onClick={togglePasswordVisiblity}>{eye}</i>{" "}

                            <p class="input-text mb-3">8 characters minimum, 1 uppercase, 1 lowercase, 1 number</p>

                            {submitted && !password &&
                                <div className="invalid-feedback">Password is required</div>
                            }
                            {submitted && !correctPassword &&
                                <div className="invalid-feedback">Password must contein minimum eight characters, at least one uppercase letter, one lowercase letter and one number</div>
                            }                    
                        </div>

                        
                        {/*
                            <input className="medium-input border-radius-9px margin-5px-bottom input-hp form-control" type="text" name="name" placeholder="Password" />                        
                        */}



                        <span className="alt-font font-weight-400 margin-20px-bottom d-block text-right title-terms">  
                            {/*
                            <a href="#" target="_blank" className="text-green font-weight-500">
                                Forgot password?
                            </a>
                            */}
                            <Link to="/forgot_password" className="text-green font-weight-500">Forgot password?</Link>
                        </span>
                        
                        <button className="btn btn-main btn-green w-100 margin-15px-bottom submit" type="submit" name="submit">Sing in</button>
                        <div className="form-results border-radius-5px d-none"></div>
                        <span className="alt-font font-weight-500 margin-20px-bottom d-block text-black text-center">New here? {' '}
                            <Link to="/confirmation" className="text-green font-weight-500">Sign up!</Link>
                        </span>
                        <span class="alt-font margin-15px-bottom d-block text-black text-center title-or">OR</span>

                        <div className="googleButtonWraper">
                            <GoogleLogin
                                clientId="827993157011-9nnqufjk99sh2e4dt0l0ui44ro2o2hmi.apps.googleusercontent.com"
                                buttonText="Sign up with Google"
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                cookiePolicy={'single_host_origin'}
                            />
                        </div>

                        <span className="alt-font font-weight-400 margin-50px-bottom d-block text-center title-terms">By logging in, you are agreeing to the  {' '}
                            {/*
                                <Link to="/confirmation" className="text-green font-weight-500 title-link">Terms of Service</Link>
                            */}

                            <Link to={{ pathname: '/terms', query: { link_id: 'li_intToServ' } }} className="text-green font-weight-500 title-link" >Terms of Service</Link>
                        </span>
                    </form>
                </div>

            </div>
        </section>

        <Footer />


    </>);
}

export { LoginPage };


/*
        <div className="col-lg-8 offset-lg-2">
            <h2>Login</h2>
            <form name="form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Username</label>
                    <input type="text" name="username" value={username} onChange={handleChange} className={'form-control' + (submitted && !username ? ' is-invalid' : '')} />
                    {submitted && !username &&
                        <div className="invalid-feedback">Username is required</div>
                    }
                </div>
                <div className="form-group">
                    <label>Password</label>
                    <input type="password" name="password" value={password} onChange={handleChange} className={'form-control' + (submitted && !password ? ' is-invalid' : '')} />
                    {submitted && !password &&
                        <div className="invalid-feedback">Password is required</div>
                    }
                </div>
                <div className="form-group">
                    <button className="btn btn-primary">
                        {loggingIn && <span className="spinner-border spinner-border-sm mr-1"></span>}
                        Login
                    </button>
                    <Link to="/register" className="btn btn-link">Register</Link>
                </div>
            </form>
        </div>
*/