import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';

import { Player, BigPlayButton, PosterImage } from 'video-react';
import 'video-react/dist/video-react.css';


function VideoModal({}) {
    const [modalShow, setModalShow] = React.useState(true);

    return (
      <>
        {/*
        <Button variant="primary" onClick={() => setModalShow(true)}>
          Launch vertically centered modal
        </Button>
        */}
  
        <MyVerticallyCenteredModal
          show={modalShow}

          onHide={() => {
            // e.preventDefault();
            setModalShow(false);
            // setVideoModalOpen(false);
           
          }
        }
        />
      </>
    );
}

function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div class="col-12 col-lg-12 md-margin-25px-bottom padding-30px-lr padding-40px-tb">

          <div class="container">
              <div class="row align-items-center justify-content-center">

                <div className="col-12 text-center">
                    <h4 className="text-black font-weight-700 text-black d-inline-block margin-30px-bottom">Welcome to Happio</h4>
                </div>

                <p class="alt-font font-weight-400" style={{lineHeight: '20px'}}> 
                  You personal wellbeing space. Stay in touch
                  with Happio to decrease stress, improve focus
                  and productivity, sllep well and feel great.
                </p>   

                <div className="col-12 text-center">
                    <h4 className="text-black font-weight-700 text-black d-inline-block margin-30px-bottom">How to use the App</h4>
                </div>                                

                <div style={{width: '100%', margin: 'auto', borderRadius: '20%'}}>
                  <Player 
                      src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
                      poster="./images/video_logo.png"
                      fluid={true}
                  >
                      {/*
                      <BigPlayButton position="center" />
                      */}
                  </Player>
                </div>

                {/*
                  <span class="alt-font font-weight-500 margin-20px-bottom d-block text-black text-center">
                  New here?  <a class="text-green font-weight-500" href="/confirmation">Sign up!</a></span>

                <br/>
                <p class="alt-font font-weight-500 margin-20px-bottom d-block text-black text-center text-green" style={{display: 'none'}}>
                  Skip {'          '}
                </p>
                */}
                <p onClick={ (e) => props.onHide(e) } style={{ cursor: 'pointer' }} class="alt-font font-weight-500 margin-20px-bottom margin-20px-top d-block text-black text-center text-green">
                  Skip {'          '}
                </p>                  

              </div>
          </div>

        </div>
      </Modal>
    );
  }

export { VideoModal };


/*
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Modal heading
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Centered Modal</h4>
          <p>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
            consectetur ac, vestibulum at eros.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
*/