import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Header, Footer, VideoModal } from '../_components';
// import { Footer } from '../_components';
// import { VideoModal } from '../_components';
import { userActions } from '../_actions';

import ScrollMenu from 'react-horizontal-scrolling-menu';

// import { Player, BigPlayButton, PosterImage } from 'video-react';
// import 'video-react/dist/video-react.css';

/**
 *
 */
function WellUsPage() {
    const dispatch = useDispatch();
    
    const [videoModalOpen, setVideoModalOpen] = useState(true);
    /*
    const loggingIn = useSelector(state => state.authentication.loggingIn);
    const location = useLocation();
    */


    useEffect(() => { 
        // dispatch(userActions.logout()); 
    }, []);

    return (<>

        <Header />
        <section class="wow fadeIn bg-white padding-36px-tb sm-padding-30px-tb page-title-medium margin-80px-top" style={{visibility: 'visible'}}>

            <VideoModal setVideoModalOpen={setVideoModalOpen} />

            <div class="container">
              <div class="row align-items-center justify-content-center">

                <div className="col-12 text-center">
                    <h4 className="text-black font-weight-700 text-black d-inline-block margin-30px-bottom">Well Us</h4>
                </div>
                <div className="col-3">

                </div>
                <div className="col-9">
                    <App />     
                </div>                

              </div>
          </div>

        </section>

        <Footer />


    </>);
}

export { WellUsPage };


/**
 

    { name: 'communities 1' },
    { name: 'communities 2' },
    { name: 'communities 3' },
    { name: 'communities 4' },
    { name: 'communities 5' },
    { name: 'communities 6' },
    { name: 'communities 7' },
    { name: 'communities 8' },
    { name: 'communities 9' }

 */
const list = [
    { name: 'item1' },
    { name: 'item2' },
    { name: 'item3' },
    { name: 'item4' },
    { name: 'item5' },
    { name: 'item6' },
    { name: 'item7' },
    { name: 'item1' },
    { name: 'item2' },
    { name: 'item3' },
    { name: 'item4' },
    { name: 'item5' },
    { name: 'item6' },
    { name: 'item7' },    
    { name: 'item8' },
    { name: 'item9' }
];


// One item component
// selected prop will be passed
/**
 * 
 * menu-banner-04 
 */
const MenuItem = ({text, selected}) => {
     
    return(<>
        <img src="./images/logo/logo.svg"></img>
        
        <div
            className={`menu-item ${selected ? 'active' : ''}`}
        >
            {text}
        </div>
    </>); 
};
  
// All items component
// Important! add unique key
const Menu = (list, selected) =>
    list.map(el => {
      const {name} = el;
  
    return <MenuItem text={name} key={name} selected={selected} />;
});


const Arrow = ({ text, className }) => {
    return (
      <div
        className={className}
      >{text}</div>
    );
};
  
  
const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
const ArrowRight = Arrow({ text: '>', className: 'arrow-next' });

const selected = 'item1';

class App extends React.Component {
    constructor(props) {
      super(props);
      // call it again if items count changes
      this.menuItems = Menu(list, selected);
    }
  
    state = {
      selected
    };
  
    onSelect = key => {
      this.setState({ selected: key });
    }
  
  
    render() {
      const { selected } = this.state;
      // Create menu from items
      const menu = this.menuItems;
  
      return (
        <div className="App">
          <ScrollMenu
            data={menu}
            arrowLeft={ArrowLeft}
            arrowRight={ArrowRight}
            selected={selected}
            onSelect={this.onSelect}
          />
        </div>
      );
    }
}