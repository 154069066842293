import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from '../_components';
import { Footer } from '../_components';
import { userActions } from '../_actions';

import AnchorLink from 'react-anchor-link-smooth-scroll';



/**
 * 
 */
function TermsPage(props) {

    // console.log('props - ', props.location.query.link_id);  // li_intToServ

    const [offset, setOffset] = useState('82');

    function autoScrollToLink(link_id) {
        let element = document.getElementById(link_id);
        element.childNodes[0].click();

        console.log('element.childNodes[0]', element.childNodes[0]);
        debugger;
    }

    useEffect(() => { 
        if (props?.location?.query?.link_id) {            
            const link_id = props.location.query.link_id;


            if (link_id) {

                // console.log('terms link_id');

                // setTimeout(() => {
                    
                    autoScrollToLink(link_id);
                // }, 1000);
            }
        }

    }, []);
    

    return (<>

        <Header />


        <section 
            class="section wow animate__fadeIn padding-36px-tb sm-padding-30px-tb" 
            style={{visibility: 'visible', paddingTop: '82px'}}
        >
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-4 col-sm-4 md-margin-15px-bottom">
                        <div class="padding-25px-left">
                            <h6 class="alt-font text-black font-weight-500 title-18px">Terms of Service</h6>
                            <ul class="list-style-term">
                            
                                
                                <li><AnchorLink offset={offset} href='#overview'>Overview</AnchorLink></li>
                                <li id="li_using-the-happio-service"><AnchorLink offset={offset} href='#using-the-happio-service'>Using the Happio Service</AnchorLink></li>
                                <li><AnchorLink offset={offset} href='#intellectual-property-rights'>Intellectual Property Rights</AnchorLink></li>
                                <li><AnchorLink offset={offset} href='#user-generated-content'>Using the Happio Service</AnchorLink></li>
                                <li><AnchorLink offset={offset} href='#termination'>Termination</AnchorLink></li>
                                <li><AnchorLink offset={offset} href='#modification-and-interruptions'>Modification and Interruptions</AnchorLink></li>
                                <li><AnchorLink offset={offset} href='#alerts-and-notifications'>Alerts and Notifications</AnchorLink></li>
                                <li><AnchorLink offset={offset} href='#links-to-and-from-other-apps-and-websites'>Links to and from Other Apps and Websites</AnchorLink></li>
                                <li><AnchorLink offset={offset} href='#lLimitations-of-liability'>Limitations of Liability</AnchorLink></li>
                                <li><AnchorLink offset={offset} href='#dispute-resolution'>Dispute Resolution</AnchorLink></li>
                                <li><AnchorLink offset={offset} href='#disclaimer'>Disclaimer</AnchorLink></li>
                                <li><AnchorLink offset={offset} href='#indemnification'>Indemnification</AnchorLink></li>
                                <li><AnchorLink offset={offset} href='#general'>General</AnchorLink></li>
                                <li><AnchorLink offset={offset} href='#contact-us'>Contact us</AnchorLink></li>
                            </ul>
                            <h6 class="alt-font text-black font-weight-500 title-18px">Privacy Policy</h6>
                            <ul class="list-style-term">
                            
                                <li><AnchorLink offset={offset} href='#privacy-overview'>Overview</AnchorLink></li>
                                <li><AnchorLink offset={offset} href='#privacy-your-rights-to-object'>Your rights to object</AnchorLink></li>
                                <li><AnchorLink offset={offset} href='#privacy-legal-basis'>Legal basis for use of your personal data</AnchorLink></li>
                                <li><AnchorLink offset={offset} href='#privacy-store-personal-information'>How long we store your personal information</AnchorLink></li>
                                <li ><AnchorLink offset={offset} href='#privacy-key-information'>The key information you should be aware of</AnchorLink></li>

                            </ul>

                            <h6 class="alt-font text-black font-weight-500 title-18px">Cookie Policy</h6>
                            <ul class="list-style-term">
                            
                                <li id="li_cookie-what-are-cookies"><AnchorLink offset={offset} href='#cookie-what-are-cookies'>What Are Cookies</AnchorLink></li>
                                <li><AnchorLink offset={offset} href='#privacy-your-rights-to-object'>How We Use Cookies</AnchorLink></li>

                            </ul>
                        </div>
                    </div>






                    <div class="col-12 col-lg-8 col-sm-8 md-margin-25px-bottom">




                        <div class="padding-15px-left">
                                

                            <div id="overview" className="margin-75px-bottom">

                            <h5 class="alt-font text-black font-weight-600">Terms of Service</h5>

                            <div id="overview" className="margin-75px-bottom">
                                {/*
                                <p class="alt-font text-black font-weight-400">
                                  These terms and conditions create a contract between you and HAPPIO (the “Agreement”). Please read the Agreement carefully. To confirm your understanding and acceptance of the Agreement, 
                                  click <span onClick={props.userAgreeTerms} style={{color: '#00da00', cursor: 'pointer'}}>“Agree.”</span>
                                </p>
                                */}
                                <p class="alt-font text-black font-weight-400">
                                Happio is a service that help you understand and improve your mental health and wellbeing. We are
registered under company name: Happio Limited at our registered address: 1 Hinde Street, London,
W1U 2AY, United Kingdom, company number 13003986.
                                </p>
                                <p class="alt-font text-black font-weight-400">
                                These Terms and Conditions (“terms”) apply to your access and use of the Happio Service. The
“Happio Service” include our mobile/web application, including source code, data-bases and
functionality (“App” and/or “Happio”) and the Happio Website (“Website”) including associated
firmware, applications, software, websites, APIs, products and services. When the Terms mention
“Happio”, “we”, “us” or “our”, they refer to the party to your agreement that provides you with the
Happio Service. These terms apply to us and all users of our services (“you”/“your”). They govern
your access to, downloading and use of all the services.
                                </p>
                                <p class="alt-font text-black font-weight-400">
                                Please read these terms carefully before using any of the Happio services. They are a legal
agreement between you and Happio. You cannot create a Happio account and to access or use the
Happio service before accepting these terms. Using any of the services indicates that you accept
these terms. If you do not abide by these terms we withhold the right to disable or terminate your
account.
                                </p>   

                                <h6 class="alt-font text-black font-weight-500 margin-20px-bottom">Privacy Policy</h6>
                                <p class="alt-font text-black font-weight-400">
                                For more information about our data practices, please read our <span style={{color: '#00da00', cursor: 'pointer'}}><AnchorLink offset={offset} href='#privacy-overview'>Privacy Policy</AnchorLink></span> section. Through using
the Happio service you agree that we can collect and use your information in accordance with
the Privacy Policy.
                                </p>

                            </div>





                                <h5 class="alt-font text-black font-weight-600">Overview</h5>
                                <p class="alt-font text-black font-weight-400">
                                    You may only connect to the Happio Service using (i) our mobile applications and software, or approved third-party applications, software or devices; or (ii) our websites (“Authorised Connections”).
                                </p>
                                <h5 class="alt-font text-black font-weight-600">Creating Your Account</h5>
                                <ul>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        Full use of the Happio Service requires that you create an account by providing us with
                                        information such as your full name and a valid email address, as well as a strong password.
                                        You are responsible for all activity that occurs in association with your account, including
                                        maintaining the confidentiality of your password and account. Your account must be used by
                                        you and nobody else and is not transferable.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        You shall immediately notify Happio should you suspect any unauthorised use of your account
                                        or password, you shall provide true, up-to-date and complete information about yourself during
                                        registration and ensure this information remains up-to-date.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        Happio is not liable for any losses or damages caused by your failure to maintain the
                                        confidentiality of your account credentials. Please contact hello@happio.io if you discover or
                                        suspect any security breach related to the Happio Service or your account.
                                        </p>
                                    </li>
                                </ul>
                                <h5 class="alt-font text-black font-weight-600">Maintenance of Your Equipment</h5>
                                <p class="alt-font text-black font-weight-400">
                                Full use of the Happio Service is dependent upon your use of a computer with adequate software or a
                                supported mobile device and Internet access. The maintenance and security of this equipment may
                                influence the performance of the Happio Service and it is your responsibility to ensure the
                                equipment’s functionality. You are responsible for all Internet access charges. Please check with your
                                Internet provider for information on possible Internet data usage charges.
                                </p>
                            </div>
                            
                            <div id="using-the-happio-service"className="margin-75px-bottom">
                                <h5 class="alt-font text-black font-weight-600">Using the Happio Service</h5>
                                <ul>
                                    <li >
                                        <p class="alt-font text-black font-weight-400">
                                        The Happio Service is intended for your personal, non-commercial use.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        Happio grants you a limited, non-exclusive, non-transferable, non-sub-licensable, revocable
                                        licence to (1) access and use the Happio Service, (2) access and view the Happio Content, (3)
                                        access and use the software and mobile applications provided by the Happio Service. With
                                        respect to items (3) this licence includes any third-party software embedded in any Happio
                                        Service. This licence is provided solely for your personal, non-commercial use and enjoyment
                                        of the Happio Service as permitted in these Terms.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        You will not use, sublicense, copy, adapt, modify, translate, disclose, prepare derivative works
                                        based upon, distribute, license, sell, rent, lease, assign, transfer, publicly display, publicly
                                        perform, transmit, broadcast, or otherwise exploit the Happio Content, Happio Service or any
                                        portion thereof (including any third-party software), except as expressly permitted in these
                                        Terms. No licences or rights are granted to you by implication or otherwise under any
                                        intellectual property rights owned or controlled by Happio or its licensors, except for the
                                        licences and rights expressly granted in these Terms.
                                        </p>
                                        <p class="alt-font text-black font-weight-400">
                                        Except to the extent permitted by law, you may not perform, attempt to perform, or encourage
                                        or assist others in performing any of the following while accessing or using the Happio Service:
                                        (1) use, display, mirror, or frame the Happio Service or any individual element within the Happio
                                        Service, including the layout and design of any page, without Happio’ express written consent;
                                        (2) use Happio’ name, any Happio trademark or logo, or any Happio proprietary information
                                        without Happio’ express written consent; (3) access or tamper with non-public areas of the
                                        Happio Service, Happio’ computer systems, or the technical delivery systems of Happio’
                                        providers; (4) test the vulnerability of any Happio’ system or breach any security or
                                        authentication measures; (5) circumvent any technological measure implemented by Happio’ or
                                        any of Happio’ providers or any other third party (including another user) to protect the Happio’
                                        Service; (6) access the Happio’ Service or Happio’ Content through the use of any mechanism
                                        other than through the use of an Authorized Connection, Happio’ Service, or Happio API; or (7)
                                        modify, decompile, disassemble, reverse engineer, tamper with, or otherwise attempt to derive
                                        the source code of any software that Happio provides to you or any other part of the Happio
                                        Service.
                                        </p>
                                        <p class="alt-font text-black font-weight-400">By using the Happio Service, you represent and warrant that: (1) you have the legal capacity
                                        and you agree to comply with these Terms of Use;  (2) you are not a minor in the jurisdiction in
                                        which you reside.
                                        </p>
                                    </li>
                                </ul>
                            </div>

                            <div id="intellectual-property-rights" className="margin-75px-bottom">
                                <h5 class="alt-font text-black font-weight-600">Intellectual Property Rights</h5>
                                <p class="alt-font text-black font-weight-400">
                                Happio owns all intellectual property rights in its services including without limitation its Apps and
                                Websites, including all software and all images, sounds, music, text, icons and other content
                                (“Content”) used within those services, other than any material which you create and which you
                                transmit or post using the services (“User Generated Content”), as well as the arrangement of that
                                Content.
                                </p>
                                <ul>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        Happio grants you a limited, personal, non-transferable, non-sublicensable, worldwide and non-
                                        exclusive licence to use its services for non-commercial use only and solely in accordance with
                                        these terms and any rules or policies applied by any app store provider or operator from whose
                                        site you downloaded an App. The grant and continuation of the above licence is conditional
                                        upon your compliance with these terms, our Privacy Policy and any rules or policies applied by
                                        any app store provider from which you downloaded an App. You shall not (and shall not allow
                                        any third party to), except to the extent expressly permitted under applicable law, copy, modify,
                                        frame, create a derivative work from, reverse engineer, reverse assemble, or otherwise attempt
                                        to discover any source code in the services.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        You shall not sell, rent, lease, loan, redistribute, assign, sublicense, republish, grant a security
                                        interest in or otherwise transfer any right in the services, in whole or in part.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        You shall not remove or modify any copyright and/or other intellectual property notices or
                                        watermarks from any Content. Nothing in these terms grants you a licence to use any Happio
                                        trademarks or the trademarks of any third parties in the services.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        We reserve the right (but are not required) to remove or disable access to the Happio Service,
                                        any Happio Content, or Your Content at any time and without notice, and at our sole discretion,
                                        if we determine that the Happio Content, Your Content, or your use of the Happio Service is
                                        objectionable or in violation of these Terms. We have the right to investigate violations of these
                                        Terms and any conduct that affects the Happio Service, and in response may take any action
                                        we may deem appropriate.
                                        </p>
                                    </li>
                                </ul>
                            </div>

                            <div id="user-generated-content" className="margin-75px-bottom">
                                <h5 class="alt-font text-black font-weight-600">User Generated Content</h5>
                                <p className="alt-font text-black font-weight-700">You are prohibited from posting to or transmitting using the services any material:</p>
                                <ol>

                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        that is threatening, defamatory, obscene, indecent, seditious, offensive, pornographic, abusive,
                                        liable to incite racial hatred, discriminatory, menacing, scandalous, inflammatory, blasphemous,
                                        in breach of confidence, in breach of privacy or which may cause annoyance or inconvenience;
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        for which you have not obtained all necessary licences and/or approvals;
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        which constitutes or encourages conduct that would be considered a criminal offence, give rise
                                        to civil liability, or otherwise be contrary to the law of or infringe the rights of any third party, in
                                        any country in the world;
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        which is technically harmful (including, without limitation, computer viruses, logic bombs, Trojan
                                        horses, worms, harmful components, corrupted data or other malicious software or harmful
                                        data);
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        contains or discloses another person’s personal information without his or her written consent;
                                        or
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        collects or solicits another person’s personal information for commercial or unlawful purposes.
                                        </p>
                                    </li>
                                </ol>
                                <p class="alt-font text-black font-weight-400">
                                Happio shall be entitled to remove any material from its services which are posted to or transmitted
                                using the services in contravention of these terms, or for any other reason.
                                </p>
                                <p class="alt-font text-black font-weight-400 mt-4">You shall not:</p>
                                <ol>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        use the services or any Content in any unlawful manner;
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        misuse the services (including, without limitation, by hacking or inserting malicious code);
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        infringe our or any third party’s intellectual property rights in your use of the services or Content;
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        use the services to transmit chain letters, junk or spam;
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        use services to harass, abuse or harm another person;
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        take any action that in our sole discretion places an unreasonable or disproportionately large
                                        load on our servers or other infrastructure; or
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        use the services in any way which may cause, or be likely to cause, access to or use of the
                                        services to be interrupted, damaged or impaired in any way.
                                        </p>
                                    </li>
                                </ol>
                                <p class="alt-font text-black font-weight-400">
                                    Happio shall be free to copy, disclose, distribute, incorporate and otherwise use such non-personal
                                    data, images, sounds, text and other things embodied therein for any and all commercial or non-
                                    commercial purposes.
                                </p>
                            </div>
                            <div id="termination" className="margin-75px-bottom">
                                <h5 class="alt-font text-black font-weight-600">Termination</h5>
                                <p class="alt-font text-black font-weight-400">
                                We reserve the right to suspend or deactivate your account or your access to certain aspects or all of
                                the Happio Service, or terminate these Terms, at our sole discretion, at any time and without notice or
                                liability to you. Upon any such suspension, deactivation or termination, we may delete or remove Your
                                Content and other information related to your account. In such circumstances, you are not entitled to
                                any refund. We will give you notice of such modification or discontinuance wherever possible.
                                </p>
                                <p class="alt-font text-black font-weight-400">
                                You may close your account at any time by contacting hello@happio.io.
                                </p>
                                <p class="alt-font text-black font-weight-400">
                                You may discontinue your use of the services (or any part of them) at any time. On termination of
                                these terms for any reason:
                                </p>
                                <ol>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        all rights granted to you under these terms shall cease;
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        you shall no longer have access to any services;
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        you must immediately cease all activities authorised by these terms; and
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        you must immediately delete or remove Apps from your devices.
                                        </p>
                                    </li>
                                </ol>
                            </div>
                            <div id="modification-and-interruptions" className="margin-75px-bottom">
                                <h5 class="alt-font text-black font-weight-600">Modification and Interruptions</h5>
                                <p class="alt-font text-black font-weight-400">
                                We reserve the right to change, modify, or remove the contents of the Happio Service at any time or
                                for any reason at our sole discretion without notice. However, we have no obligation to update any
                                information on the Happio Service. We also reserve the right to modify or discontinue all or part of the
                                Happio Service without notice at any time. We will not be liable to you or any third party for any
                                modification, price change, suspension, or discontinuance of the Happio Service.
                                </p>
                                <p class="alt-font text-black font-weight-400">
                                We cannot guarantee the Happio Service will be available at all times. We may experience hardware,
                                software, or other problems or need to perform maintenance related to the Happio Service resulting in
                                interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue,
                                or otherwise modify the Happio Service at any time or for any reason without notice to you. You agree
                                that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability
                                to access or use the Site during any downtime or discontinuance of the Happio Service. Nothing in
                                these Terms of Use will be construed to obligate us to maintain and support the Site or to supply any
                                corrections, updates, or releases in connection therewith.
                                </p>
                            </div>

                            <div id="alerts-and-notifications" className="margin-75px-bottom">
                                <h5 class="alt-font text-black font-weight-600">Alerts and Notifications</h5>
                                <p class="alt-font text-black font-weight-400">
                                As part of your use of the Happio Service, you may receive notifications, text messages, alerts,
                                emails, and other electronic communications. You agree to the receipt of these communications.
                                </p>
                                <p class="alt-font text-black font-weight-400">
                                We may need to provide you with certain communications, such as service announcements and
                                administrative messages. You are responsible for any messaging or data fees you may be charged
                                by your wireless carrier. Any notices, agreements, disclosures, or other communications that we send
                                to you electronically will satisfy any legal communication requirements, including that the
                                communication be in writing.
                                </p>
                            </div>

                            <div id="links-to-and-from-other-apps-and-websites" className="margin-75px-bottom">
                                <h5 class="alt-font text-black font-weight-600">Links to and from Other Apps and Websites</h5>
                                <p class="alt-font text-black font-weight-400">
                                Any links to third party apps and websites are provided solely for your convenience. We have not
                                reviewed all of these third party apps and websites and do not control and are not responsible for
                                these apps/websites or their content or availability. We do not endorse or make any representations
                                about them, or any material found there, or any results that may be obtained from using them. If you
                                decide to access any of the third party apps/websites linked to from our services, you do so entirely at
                                your own risk.
                                </p>
                            </div>


                            <div id="lLimitations-of-liability" className="margin-75px-bottom">
                                <h5 class="alt-font text-black font-weight-600">Limitations of Liability</h5>
                                <p class="alt-font text-black font-weight-400">
                                Nothing in these terms shall exclude or limit your liability or our liability for death or personal injury
                                caused by negligence; for fraud or fraudulent misrepresentation; or under any indemnity in these
                                terms. Subject to this, we shall not be liable in contract, tort (including, without limitation, negligence),
                                for pre-contract or other representations, or otherwise arising out of or in connection with these terms
                                for:
                                </p>
                                <ol>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        any economic losses (including, without limitation, loss of revenues, profits, contracts, data,
                                        business, anticipated savings or cost of substitute services);
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        any loss of goodwill or reputation; or
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        any special, indirect or consequential losses, in any case, whether or not such losses were
                                        within either of our contemplations at the date of acceptance of these terms.
                                        </p>
                                    </li>
                                </ol>
                                <p class="alt-font text-black font-weight-400">
                                Subject to the above, our liability to you in relation to all events or series of connected events
                                occurring under these terms (and whether the liability arises because of breach of contract,
                                negligence or for any other reason) shall be limited to the greater of the total amount of the sums you
                                have paid for the services in the six month period immediately preceding the date of the first event
                                allegedly giving rise to the liability.
                                </p>
                                <p class="alt-font text-black font-weight-400">
                                Nothing in these terms excludes any statutory rights which may apply to your use of the services
                                which cannot be excluded, restricted or modified by contract.
                                </p>
                            </div>

                            <div id="dispute-resolution" className="margin-75px-bottom">
                                <h5 class="alt-font text-black font-weight-600">Dispute Resolution</h5>
                                <h6 class="alt-font text-black font-weight-500">Governing law</h6>
                                <p class="alt-font text-black font-weight-400">
                                This Agreement and any matters arising out of it shall be governed by and construed in accordance
                                with the laws of England.
                                </p>
                                <h6 class="alt-font text-black font-weight-500">Mediation</h6>
                                <p class="alt-font text-black font-weight-400">
                                Any dispute relating to this Agreement which cannot be resolved by negotiation between the parties
                                within 30 days of either party having given notice to the other party that a dispute has arisen shall be
                                submitted to Third-Party Services.
                                </p>
                                <p class="alt-font text-black font-weight-400">
                                In addition, you have the option to use the Online Dispute Resolution (ODR) platform which is
                                accessible <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp%3Blng=EN" target="_blank">here</a>.
                                </p>
                            </div>

                            <div id="disclaimer" className="margin-75px-bottom">
                                <h5 class="alt-font text-black font-weight-600">Disclaimer</h5>
                                <ul>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        The Happio service is provided “as is”, without any conditions, warranties or other terms of any
                                        kind and is used by you at your own risk. Accordingly, to the maximum extent permitted by law,
                                        we provide you with the services on the basis that we exclude all representation, warranties,
                                        conditions and other terms which, but for these terms, might have effect in relation to the
                                        services.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        While we endeavour to ensure that the Content contained within the services is correct, we do
                                        not warrant the accuracy and completeness of that Content. We may make changes to the
                                        Content at any time without notice. The Content may be out of date and Happio makes no
                                        commitment to update such material.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        We make no warranty that the Happio Service will meet your requirements or be available on
                                        an uninterrupted, secure, or error-free basis. We make no warranty regarding the quality,
                                        accuracy, timeliness, truthfulness, completeness, or reliability of the Happio Service.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        Any health information included in the services or linked to from the services is for general
                                        information purposes only and should not be relied upon. If you want medical advice you
                                        should contact a doctor. If you have an existing condition such as epilepsy, or motion sickness
                                        which you think may be affected by using the services, you should contact a doctor before
                                        using the services.
                                        </p>
                                    </li>
                                </ul>
                                <h6 class="alt-font text-black font-weight-500">Use Happio Service at Your Own Risk</h6>
                                <ul>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        If you rely on any Happio Content or the Happio Service, you do so solely at your own risk.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        Our goal is to provide helpful and accurate information on the Happio Service, but we make no
                                        endorsement, representation, or warranty of any kind about any Happio Content, information or
                                        services.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        The accuracy of the data collected and presented through the Happio Service is not intended to
                                        match that of medical devices or scientific measurement devices.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        We are not responsible for the accuracy, reliability, availability, effectiveness or correct use of
                                        information you receive through the Happio Service.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                        Use of the Happio Service should not replace your good judgement and common sense.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                            Use of the Happio service is not a substitute for seeking diagnosis and treatment from a
                                            qualified professional. An accurate diagnosis of stress, burnout, depression, anxiety and other
                                            psychiatric disorders can only be made by a physician or qualified mental health professional
                                            after a complete evaluation, including a physical exam to rule out any medical illness or
                                            conditions. The Happio app is furthermore not designed to treat serious mental or physical
                                            health problems.
                                        </p>
                                    </li>                                  
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                            Data from the Happio service is not overseen by a medical professional or medical regulatory
                                            authority. If you experience any health problems and believe you have any mental or physical
                                            illness consult with a medical professional.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                            AIf you engage in any course content or tool through the Happio Service, you agree that you do
                                            so at your own risk and are voluntarily participating in these activities.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                            Happio and its staff shall have no liability for claims by or damages of any kind whatsoever to
                                            you or any other person for a decision or action taken in reliance on the information contained
                                            in the Happio service or related communication channels. Such damages include, without
                                            limitation, direct, indirect, special, incidental or consequential damages.
                                        </p>
                                    </li>
                                </ul>
                            </div>

                            <div id="indemnification" className="margin-75px-bottom">
                            <h5 class="alt-font text-black font-weight-600">Indemnification</h5>
                                <p class="alt-font text-black font-weight-400">
                                    You indemnify and hold Happio and its officers, directors, agents and employees, harmless from any
                                    losses, damages, costs, expenses (including reasonable legal fees) or other liability, arising out of any
                                    claim, demand, allegation or proceeding brought by any third party due to or arising out of any breach
                                    by you of any of these terms or applicable law; your misuse of the services; Your Content; your
                                    infringement of intellectual property rights or any other third party rights; and/or your failure to provide
                                    accurate, up to date information.
                                </p>
                            </div>

                            <div id="general" className="margin-75px-bottom">
                                <h5 class="alt-font text-black font-weight-600">General</h5>
                                <p class="alt-font text-black font-weight-400">
                                    We will not be liable or responsible for any failure to perform, or delay in performance of, any of our
                                    obligations under these terms that is caused by any act or event beyond our reasonable control,
                                    including failure of public or private telecommunications networks (“Event Outside Our Control”). If an
                                    Event Outside Our Control takes place that affects the performance of our obligations under these
                                    terms of use:
                                </p>
                                <ol>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                            Your obligations under these terms shall be suspended for the duration of the Event Outside Our
                                            Control; and
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                            we will use our reasonable endeavours to find a solution by which our obligations under these
                                            terms may be performed despite the Event Outside Our Control.
                                        </p>
                                    </li>
                                </ol>
                                <p class="alt-font text-black font-weight-400">No person other than you and Happio shall have any rights under these terms in relation to your use
                                    of the services. We may transfer our rights and obligations under these terms to another organisation,
                                    but this will not affect your rights or our obligations under these terms. You may only transfer your
                                    rights or obligations under these terms if we agree in writing.
                                </p>
                                    <p class="alt-font text-black font-weight-400">
                                    Any failure of or delay by Happio to exercise or enforce any right or provision of the terms shall not
                                    constitute a waiver of this right or provision. If any provision of the terms is found by a court of
                                    competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavour to
                                    give effect to the parties’ intentions as reflected in the provision, and the other provisions of the terms
                                    shall remain in full force and effect.
                                </p>
                                <p class="alt-font text-black font-weight-400">
                                    You agree that these terms represent the entire understanding between you and Happio with respect
                                    to your relationship with Happio. These terms supersede any previous agreements between you and
                                    Happio including any previous version of these terms.
                                </p>
                                <h6 class="alt-font text-black font-weight-500">General terms</h6>
                                <p class="alt-font text-black font-weight-400">
                                    We will notify you before we make material changes to these Terms and give you an opportunity to
                                    review the revised Terms before continuing to use the Happio Service. When you use the Happio
                                    Service after a modification becomes effective, you are telling us that you accept the modified Terms.
                                </p>
                                <p class="alt-font text-black font-weight-400">
                                    Any notices or other communications provided by Happio under these Terms, including those
                                    regarding modifications to these Terms, will be given: (i) via email or (ii) by posting to the Happio
                                    Service. For notices made by e-mail, the date of receipt on the message will be deemed the date on
                                    which such notice is transmitted.
                                </p>
                            </div>

                            <div id="contact-us" className="margin-75px-bottom">
                                <h5 class="alt-font text-black font-weight-600">Contact us</h5>
                                <p class="alt-font text-black font-weight-400">
                                    Please contact us if you have any questions about these terms
                                </p>
                                <p class="alt-font text-black font-weight-400">
                                    Email: hello@happio.io
                                </p>
                                <p class="alt-font text-black font-weight-400">
                                    Postal Address: 1 Hinde Street, London, W1U 2AY, United Kingdom
                                </p>
                            </div>

                            <div id="privacy-overview" className="margin-75px-bottom">
                                <h4 class="alt-font text-black font-weight-600">Privacy Policy</h4>
                                <h5 class="alt-font text-black font-weight-600">Overview</h5>
                                <p class="alt-font text-black font-weight-600">Our values and what this policy is for</p>
                                <p class="alt-font text-black font-weight-400">
                                    We value your privacy and want to be accountable and fair to you as well as transparent with you in
                                    the way that we collect and use your personal information.
                                </p>
                                <p class="alt-font text-black font-weight-400">
                                    In line with these values, this privacy policy tells you what to expect when we collect and use personal
                                    information about you. We have tried to make it easy for you to navigate so you can find the
                                    information that is most relevant to you and our relationship with you.Our processes are built to be
                                    General Data Protection Regulation (GDPR) compliant. We are always looking to improve the
                                    information we provide to our customers and contacts so if you have any feedback on this privacy
                                    policy, please let us
                                </p>
                            </div>

                            <div id="privacy-your-rights-to-object" className="margin-75px-bottom">
                                <h5 class="alt-font text-black font-weight-600">Your rights to object</h5>
                                <p class="alt-font text-black font-weight-400">
                                    You have various rights in respect of our use of your personal information as set out in section 5. We
                                    hereby want to explicitly bring to your attention some of the fundamental rights to be aware of:
                                </p>
                                <ol>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                            you may ask us to stop using your personal information for direct-marketing purposes. If you
                                            exercise this right, we will stop using your personal information for this purpose.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                            you may ask us to consider any valid objections which you have to our use of your personal
                                            information where we process your personal information on the basis of our, or another
                                            person’s, legitimate interest. You can find out more information in section 5.
                                        </p>
                                    </li>
                                </ol>
                            </div>

                            <div id="privacy-legal-basis" className="margin-75px-bottom">
                                <h5 class="alt-font text-black font-weight-600">Legal basis for use of your personal data</h5>
                                <p class="alt-font text-black font-weight-400">
                                This Agreement and any matters arising out of it shall be governed by and construed in accordance
                                with the laws of England.
                                </p>
                                <ol>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                            The legal bases for using your personal information as set out in this privacy policy are as
                                            follows:
                                        </p>
                                            <ul>
                                                <p class="alt-font text-black font-weight-400">
                                                    <li>our use of your personal information is necessary to perform our obligations under any
                                                    contract with you (for example, to comply with the terms of use of our app, platform or
                                                    website which you accept by registering for app and platform or browsing our website);
                                                    or</li>
                                                </p>
                                                <p class="alt-font text-black font-weight-400">
                                                    <li>our use of your personal information is necessary for complying with our legal
                                                    obligations (for example, due to tax retention periods); or</li>
                                                </p> 
                                                <p class="alt-font text-black font-weight-400">
                                                    <li>where neither (a) nor (b) apply, use of your personal information is necessary for our
                                                    legitimate interests or the legitimate interests of others (for example, to ensure the
                                                    security of our website). Our legitimate interests may be to:
                                                    </li>
                                                </p>
                                                                                                                    
                                                    
                                                <p class="alt-font text-black font-weight-400">
                                                    <li>If we rely on our (or another person’s) legitimate interests for using your personal
                                                    information, we will undertake a balancing test to ensure that our (or the other
                                                    person’s) legitimate interests are not outweighed by your interests or fundamental
                                                    rights and freedoms which require protection of the personal information. You can ask
                                                    us for information on this balancing test by using the contact details in section 10.
                                                    </li>
                                                    <ul>
                                                        <li>
                                                        run, grow and develop our business (as well as the businesses of our group
                                                        companies);
                                                        </li>
                                                        <li>
                                                        operate and improve our website and the app;
                                                        </li>
                                                        <li>
                                                        select appropriately skilled and qualified suppliers;
                                                        </li>
                                                        <li>
                                                        ensure a safe environment for our residents and suppliers; 
                                                        </li>
                                                        <li>
                                                        carry out marketing, market research and business development;
                                                        </li>
                                                        <li>
                                                        place, track and ensure fulfilment of orders with our suppliers; and
                                                        </li>
                                                        <li>
                                                        for internal group administrative purposes.
                                                        </li>
                                                    </ul>
                                                </p>
                                            </ul>
                                        <p class="alt-font text-black font-weight-400">
                                        <li>We may process your personal information in some cases for marketing purposes on the basis
                                        of your consent (which you may withdraw at any time after giving it, as described below).</li>
                                        </p>
                                        <p class="alt-font text-black font-weight-400">
                                        <li>If we rely on your consent for us to use your personal information in a particular way, but you
                                        later change your mind, you may withdraw your consent by contacting us
                                        at hello@happio.io and we will stop doing so. Please be aware that not providing this consent
                                        or subsequently withdrawing it will mean that your account will be deleted and you will no
                                        longer have access to the Services.</li>
                                        </p>
                                    </li>
                                </ol>
                            </div>

                            <div id="privacy-store-personal-information" className="margin-75px-bottom">
                                <h5 class="alt-font text-black font-weight-600">How long we store your personal information</h5>
                                <p class="alt-font text-black font-weight-400">
                                We keep your personal information for no longer than necessary for the purposes for which the
                                personal information is processed. The length of time for which we retain personal information
                                depends on the purposes for which we collect and use it and/or as required to comply with applicable
                                laws and to establish, exercise or defend our legal rights.
                                </p>
                            </div>

                            <div id="privacy-key-information" className="margin-75px-bottom">
                                <h5 class="alt-font text-black font-weight-600">The key information you should be aware of</h5>
                                <p class="alt-font text-black font-weight-600">1. How we obtain your personal information.</p>
                                <p class="alt-font text-black font-weight-400">
                                    1.1 You may provide us with your personal information voluntarily (section 1.2). We may also receive
                                    information about you from third parties such marketing agencies, market research companies, our
                                    suppliers, contractors and consultants, public websites and public agencies, which we refer to as
                                    “third party sources” or “suppliers” throughout this policy.</p>
                                <p class="alt-font text-black font-weight-400">1.2 You may give us personal information about yourself by using the online forms provided on our
                                    website, setting up an account with us or in our app, using bulletin boards or forums on our website,
                                    or by contacting us by phone, email or other means. This includes, for example, where you provide
                                    your personal information to us in order to receive products, deliveries, information or services from
                                    us. If you are a supplier, you may also give us personal information about you when you are offering
                                    or providing services to us.</p>
                                <p class="alt-font text-black font-weight-600">2. How we use your personal data</p>
                                <p class="alt-font text-black font-weight-400">Please go to the section or sections below that best describes our relationship with you to find out the
                                information that we collect about you and how we use this information. Any information that we refer
                                to as “personal information” throughout this policy constitutes “personal data”.</p>
                                <p class="alt-font text-black font-weight-600">2.1 Visitors to our website and app</p>
                                <p class="alt-font text-black font-weight-600">a. Visiting</p>
                                <p class="alt-font text-black font-weight-600">Categories of personal data</p>
                                    <ul>
                                        <li> 
                                            <p class="alt-font text-black"><span class="font-weight-600">Technical information.</span> This includes: the Internet Protocol (IP) address used to connect your
                                            computer to the internet address; the website address and country from which you access
                                            information; the files requested; browser type and version; browser plug-in types and versions;
                                            operating system; and platform. We use this personal information to administer our website, to
                                            measure the efficiency of our systems and to undertake an analysis on the locations from which
                                            people access our webpages; and
                                            </p></li>
                                            <li> 
                                            <p class="alt-font text-black"><span class="font-weight-600">Information about your visit and your behaviour on our website (for example, the pages
                                            that you click on).</span> This may include the website you visit before and after visiting our website
                                            (including date and time), time and length of visits to certain pages, page interaction information
                                            (such as scrolling, clicks, and mouse-overs), methods used to browse away from the page,
                                            traffic data, location data, weblogs and other communication data and information provided
                                            when requesting further service or downloads.
                                            </p>
                                        </li>
                                    </ul>
                                <p class="alt-font text-black"><span class="font-weight-600">Purposes of the processing:</span>  We will collect, use and store the personal information listed above for
                                    the following reasons:</p>

                                    <ul className="margin-25px-bottom">
                                        <li class="alt-font text-black font-weight-400">
                                            to allow you to access and use our website;
                                        </li>
                                        <li class="alt-font text-black font-weight-400">for improvement and maintenance of our website and to provide technical support for our
                                        website;</li>
                                        <li class="alt-font text-black font-weight-400">to recognise you when you return to our website, to store information about your preferences,
                                        and to allow us to customise the website according to your individual interests; and</li>
                                        <li class="alt-font text-black font-weight-400">to evaluate your visit to the website and prepare reports or compile statistics to understand the
                                        type of people who use our website, how they use our website and to make our website more
                                        intuitive. Such details will be anonymised as far as reasonably possible and you will not be
                                        identifiable from the information collected.</li>
                                    </ul>

                                <p class="alt-font text-black"><span class="font-weight-600">Legal basis for the processing:</span> We process your personal information based upon our legitimate
                                interests to</p>

                                    <ul className="margin-25px-bottom">
                                        <li class="alt-font text-black font-weight-400">
                                        run, grow and develop our business (as well as the businesses of our group companies);
                                        </li>
                                        <li class="alt-font text-black font-weight-400">operate and improve our website and the app;</li>
                                        <li class="alt-font text-black font-weight-400">carry out marketing, market research and business development;</li>
                                        <li class="alt-font text-black font-weight-400">for internal group administrative purposes</li>
                                    </ul>


                                <p class="alt-font text-black font-weight-600">b. Contacting us through our website, app</p>
                                <p class="alt-font text-black"><span class="font-weight-600">Categories of personal data:</span> 
                                We may process through our website your name and contact data, if
                                so required also information concerning your employment relationship or your date of birth as well as
                                information you provide to us throughout our correspondence.
                                </p>
                                <p class="alt-font text-black"><span class="font-weight-600">Purposes of the processing:</span> 
                                 We will collect, use and store the personal information listed above for
                                    the following reasons:
                                </p>
                                    <ul className="margin-25px-bottom">
                                        <li class="alt-font text-black font-weight-400">
                                        to allow you to provide information on projects on which you are working and on which you
                                        would like to inquire;
                                        </li>
                                        <li class="alt-font text-black font-weight-400">to register to receive sales or other notifications and materials;</li>
                                        <li class="alt-font text-black font-weight-400">carry out marketing, market research and business development;</li>
                                        <li class="alt-font text-black font-weight-400">to receive enquiries from you through the website about our website, app and associated
                                        services;</li>
                                    </ul>
                                <p class="alt-font text-black"><span class="font-weight-600">Legal basis for the processing:</span> 
                                  Processing your personal information for the purpose of contacting
                                    us will be on the basis of your voluntarily given consent, or such processing may be necessary in
                                    order to handle (pre-) contractual enquiries.
                                </p>
                                <p class="alt-font text-black"><span class="font-weight-600">Retention period:</span> 
                                   We will delete the data collected and processed in the context of our
                                    correspondence upon completion of your request, unless we have a legal retention obligation to
                                    observe.
                                </p>
                                <p class="alt-font text-black font-weight-6400">Please see sections 2.6 and 2.7 for more details 
                                    about how we use your personal information.
                                </p>
                                <p class="alt-font text-black font-weight-600">2.2 Customers, and employees of customers</p>
                                <p class="alt-font text-black font-weight-600">a. Conclusion and performance of contracts</p>
                                <p class="alt-font text-black"><span class="font-weight-600">Categories of personal data:</span> 
                                    We may process your name and contact data, if so required also
                                    information concerning your employment relationship or your date of birth, contract and payment
                                    information as well as information you provide to us throughout our correspondence. In some cases,
                                    we may also process access data to our products or services that have been provided to you or
                                    generated by you.
                                </p>
                                <p class="alt-font text-black"><span class="font-weight-600">Special categories of personal data:</span> 
                                    Some of the personal information that we collect about you or
                                    which you provide to us about you or your family members may be special categories of data. Special
                                    categories of data include information about physical and mental health, sexual orientation, racial or
                                    ethnic origin, political opinions, philosophical belief, trade union membership and biometric data.
                                </p>
                                <p class="alt-font text-black"><span class="font-weight-600">Purposes of the processing:</span> 
                                    We will collect, use and store the personal information listed above for
                                    the following reasons:
                                </p>
                                    <ul className="margin-25px-bottom">
                                        <li class="alt-font text-black font-weight-400">to provide you with our interfaces, which help you manage our services;</li>
                                        <li class="alt-font text-black font-weight-400">to facilitate deliveries of data, new products and services to you;</li>
                                        <li class="alt-font text-black font-weight-400">to deal with any enquiries or issues you have about our x app, and associated services that you
                                            request from us;</li>
                                        <li class="alt-font text-black font-weight-400">to send you certain communications (including by email or post) about our products and
                                            services such as administrative messages (for example, setting out changes to our terms and
                                            conditions and keeping you informed about our fees and charges);</li>
                                    </ul>

                                <p class="alt-font text-black"><span class="font-weight-600">Legal basis for the processing:</span> 
                                    The processing is necessary for the handling of (pre-)contractual
                                    enquiries or for the conclusion and performance of contractual relationships to which you are a party.
                                    If you are not yourself a party to a contract with us, but an employee of one of our customers, we
                                    process your personal data on the basis of our legitimate interest to run, grow and develop our
                                    business (as well as the businesses of our group companies). We process special categories of
                                    personal data exclusively on the basis of your consent.
                                </p>

                                <p class="alt-font text-black"><span class="font-weight-600">Retention period:</span> 
                                    The data collected and processed within the scope of contractual relationships
                                    with us will be deleted upon completion of your request, if necessary after the expiry of contractual
                                    warranty and/or liability periods as well as statutory retention periods. Insofar as the processing of
                                    personal data is based on your consent, we will delete this data if you revoke your consent to us.
                                </p>
                                    
                                <p class="alt-font text-black font-weight-600">b. Improving our products and services</p>
                                <p class="alt-font text-black"><span class="font-weight-600">Categories of personal data:</span> 
                                    In addition to the data mentioned in lit. a, we may also process the
                                    following personal data:
                                </p>
                                    <ul className="margin-25px-bottom">
                                        <li class="alt-font text-black font-weight-400">information about the provision of existing or potential projects or studies using or developing
                                            new elements of the app, and the associated services we provide (or may provide) to you
                                            including (but not limited to):</li>

                                            <ul>
                                                <li class="alt-font text-black font-weight-400">
                                                information needed to provide the services to you, or develop future services (including
                                                information on joining forms, order details, order history and payment details);
                                                </li>
                                                <li class="alt-font text-black font-weight-400">
                                                customer/participant services information; and
                                                </li>
                                                <li class="alt-font text-black font-weight-400">
                                                customer/participant management and marketing information;
                                                </li>
                                            </ul>
 
                                        <li class="alt-font text-black font-weight-400">information you provide to help us provide you with improved service, for example, if we ask
                                            you to fill in a survey or questionnaire.</li>
                                    </ul>
                                <p class="alt-font text-black"><span class="font-weight-600">Special categories of personal data:</span> 
                                    Some of the personal information that we collect about you or
                                    which you provide to us about you or your family members may be special categories of data. Special
                                    categories of data include information about physical and mental health, sexual orientation, racial or
                                    ethnic origin, political opinions, philosophical belief, trade union membership and biometric data.
                                </p>
                                <p class="alt-font text-black"><span class="font-weight-600">Purposes of the processing:</span> 
                                 We will collect, use and store the personal information listed above fors the following reasons:
                                </p>
                                    <ul className="margin-25px-bottom">
                                        <li class="alt-font text-black font-weight-400">
                                        to carry out statistical analysis, product research and feedback and market research on people
                                        who may be interested in our existing or new Happio products and services; and
                                        </li>
                                        <li class="alt-font text-black font-weight-400">
                                        if it is in our legitimate interests for business development and marketing purposes, to contact
                                        you (including by telephone or post) with information about our products and services or the
                                        products and services of our suppliers which either you request, or which we feel will be of
                                        interest to you; and
                                        </li>
                                        <li class="alt-font text-black font-weight-400">
                                        if you are a sole trader or a non-limited liability partnership and if you have consented, to
                                        contact you by email with information about our products and services or the products and
                                        services of our suppliers which either you request or which we feel will be of interest to you.
                                        </li>
                                    </ul>

                                <p class="alt-font text-black"><span class="font-weight-600">Legal basis for the processing: </span> 
                                The processing is partly necessary for the handling of (pre-)
                                contractual enquiries or for the conclusion and performance of contractual relationships to which you
                                are a party. If you are not yourself a party to a contract with us, but an employee of one of our
                                customers, we process your personal data on the basis of our legitimate interest to run, grow and
                                develop our business (as well as the businesses of our group companies) as well as to carry out
                                marketing, market research and business development. We process special categories of personal
                                data exclusively on the basis of your consent.
                                </p>
                                <p class="alt-font text-black"><span class="font-weight-600">Retention period: </span> 
                                 The data collected and processed within the scope of contractual relationships
                                with us will be deleted upon completion of your request, if necessary after the expiry of contractual
                                warranty and/or liability periods as well as statutory retention periods. Insofar as the processing of
                                personal data is based on your consent, we will delete this data if you revoke your consent to us.
                                </p>
                                <p class="alt-font text-black font-weight-400">Please see sections 2.6 and 2.7 for more details about how we use your personal information.</p>
                                <p class="alt-font text-black"><span class="font-weight-600">Information we need to provide services to you. </span> 
                                We need certain types of personal information so
                                that we can provide services to you and perform contractual and other legal obligations that we have
                                to you. If you do not provide us with such personal information, or if you ask us to delete it, you may
                                no longer be able to access the app.
                                </p>

                                <p class="alt-font text-black font-weight-600">2.3 Prospective customers to whom we send marketing communications</p>
                                <p class="alt-font text-black"><span class="font-weight-600">Categories of personal data: </span> 
                                    We may process your name and contact data, if so required also
                                    information about your preferences.
                                </p>
                                <p class="alt-font text-black"><span class="font-weight-600">Source of personal information. </span> 
                                    We may receive some of your personal information from third
                                    parties, such as marketing agencies.
                                </p>
                                <p class="alt-font text-black"><span class="font-weight-600">Purposes of the processing:</span> 
                                    We will collect, use and store the personal information listed above to
                                    contact you with information about our products and services which either you request, or which we
                                    feel will be of interest to you (including newsletters).
                                </p>
                                <p class="alt-font text-black"><span class="font-weight-600">Legal basis for the processing: </span> 
                                    We collect, use and store the personal information listed above, if
                                    you have consented or, otherwise, if it is in our legitimate interests, for business development and
                                    marketing purposes.
                                </p>
                                <p class="alt-font text-black"><span class="font-weight-600">Retention period: </span> 
                                    We delete your data, if you withdraw your consent to us or object to the
                                    processing. Please note that we may further store your data upon withdrawal or objection, if this is
                                    required to serve our legitimate interest in ensuring adherence to your withdrawal or objection (e.g. by
                                    keeping a blacklist).
                                </p>
                                <p class="alt-font text-black font-weight-400">Please see sections 2.7 and 2.8 for more details about how we use your personal information.</p>

                                <p class="alt-font text-black font-weight-600">2.4 Job candidates who wish to join the Happio team</p>
                                <p class="alt-font text-black"><span class="font-weight-600">Categories of personal data: </span> 
                                    We may process your name and contact data as well as CVs and
                                    information about your employment history and other contact details of references you may have
                                    provided to us. Depending on the nature of the role and the applicable jurisdiction, from time to time,
                                    we may also collect sensitive personal information about you, such as information about your health,
                                    background check information, including criminal history, and whether you are a member of any
                                    professional or trade associations.
                                </p>
                                <p class="alt-font text-black"><span class="font-weight-600">Special categories of personal data: </span> 
                                    Some of the personal information that we collect about you or
                                    which you provide to us about you or your family members may be special categories of data. Special
                                    categories of data include information about physical and mental health, sexual orientation, racial or
                                    ethnic origin, political opinions, philosophical belief, trade union membership and biometric data.
                                </p>
                                <p class="alt-font text-black"><span class="font-weight-600">Sources of personal data: </span> 
                                    We may receive some of your personal information from third parties,
                                    such as recruitment agencies or through referrals.
                                </p>
                                <p class="alt-font text-black"><span class="font-weight-600">Purposes of the processing: </span> 
                                    We will collect, use and store the personal information listed above to
                                    assess your suitability for any vacant roles, to contact you with information about our company,
                                    products and services, recruitment process and progress of your application which either you request,
                                    or which we feel will be of interest to you.
                                </p>
                                <p class="alt-font text-black"><span class="font-weight-600">Legal basis for the processing:  </span> 
                                    The processing is partly necessary for the handling of (pre-)
                                    contractual enquiries or for the conclusion and performance of contractual relationships to which you
                                    are a party. If you are not yourself a party to a contract with us, but an employee of one of our
                                    customers, we process your personal data on the basis of our legitimate interest to run, grow and
                                    develop our business (as well as the businesses of our group companies) as well as to carry out
                                    marketing, market research and business development. We process special categories of personal
                                    data exclusively on the basis of your consent.
                                </p>
                                <p class="alt-font text-black"><span class="font-weight-600">Retention period: </span> 
                                    We delete your data, if you withdraw your consent to us or object to the
                                    processing. Please note that we may further store your data upon withdrawal or objection, if this is
                                    required to serve our legitimate interest in ensuring adherence to your withdrawal or objection (e.g. by
                                    keeping a blacklist). If the processing is related to a specific job application procedure, we will store
                                    your personal information until the procedure is closed and for a limited period thereafter on the basis
                                    of our legitimate interest to defend ourselves against claims related to the job application procedure.
                                </p>
                                <p class="alt-font text-black font-weight-400">Please see sections 2.6 and 2.7 for more details about how we use your personal information.</p>

                                <p class="alt-font text-black font-weight-600">2.5 Our suppliers, and employees of our suppliers</p>
                                <p class="alt-font text-black"><span class="font-weight-600">Categories of personal data: </span> 
                                    We may process the following information about you:
                                </p>
                                    <ol className="margin-25px-bottom">
                                        <li class="alt-font text-black font-weight-400">your name including your title;</li>
                                        <li class="alt-font text-black font-weight-400">work contact information (phone number, postal address, mailing address, email address);</li>
                                        <li class="alt-font text-black font-weight-400">information provided when you correspond with us;</li>
                                        <li class="alt-font text-black font-weight-400">any updates to information provided to us;</li>
                                        <li class="alt-font text-black font-weight-400">personal information we collect about you from third party sources such as LinkedIn:</li>
                                        <li class="alt-font text-black font-weight-400">CVs, pitch and tender information;</li>
                                        <li class="alt-font text-black font-weight-400">proof of identification and address;</li>
                                        <li class="alt-font text-black font-weight-400">visa or work permit documentation;</li>
                                        <li class="alt-font text-black font-weight-400">details of compensation, expense claims and bank details;</li>
                                        <li class="alt-font text-black font-weight-400">information required to access company systems and applications (such as system ID).</li>
                                    </ol>
                                <p class="alt-font text-black"><span class="font-weight-600">Special categories of personal data: </span> 
                                    Some of the personal information that we collect about you or
                                    which you provide to us about you or your family members may be special categories of data. Special
                                    categories of data include information about physical and mental health, sexual orientation, racial or
                                    ethnic origin, political opinions, philosophical belief, trade union membership and biometric data.
                                    Sources of personal data: We may receive some of your personal information from third party
                                    sources, such as your employer or your employer’s company website. We may also collect this
                                    personal information from publicly available sources, such as LinkedIn.
                                </p>

                                <p class="alt-font text-black"><span class="font-weight-600">Purposes of the processing: </span> 
                                    We may collect, use and store the personal information listed above
                                    for the following reasons:
                                </p>
                                    <ol className="margin-25px-bottom">
                                        <li class="alt-font text-black font-weight-400">to enable us to purchase and receive products and services from you (including supplier due
                                            diligence, payment and expense reporting and financial audits);</li>
                                        <li class="alt-font text-black font-weight-400">to deal with enquiries from you;</li>
                                        <li class="alt-font text-black font-weight-400">to confirm the information on CVs and performance reference checks, to assess you or your
                                            employer’s suitability to work for us;</li>
                                        <li class="alt-font text-black font-weight-400">for equal opportunities monitoring;</li>
                                        <li class="alt-font text-black font-weight-400">for health and safety records and management; and</li>
                                        <li class="alt-font text-black font-weight-400">for security vetting and criminal records checks (where applicable and allowed by law).</li>
                                    </ol>

                                <p class="alt-font text-black"><span class="font-weight-600">Legal basis for the processing: </span> 
                                    The processing is partly necessary for the handling of (pre-)
                                    contractual enquiries or for the conclusion and performance of contractual relationships to which you
                                    are a party. If you are not yourself a party to a contract with us, but an employee of one of our
                                    customers, we process your personal data on the basis of our legitimate interests to
                                </p>
                                    <ol className="margin-25px-bottom">
                                        <li class="alt-font text-black font-weight-400">run, grow and develop our business (as well as the businesses of our group companies);</li>
                                        <li class="alt-font text-black font-weight-400">select appropriately skilled and qualified suppliers;</li>
                                        <li class="alt-font text-black font-weight-400">ensure a safe environment for our residents and suppliers;</li>
                                        <li class="alt-font text-black font-weight-400">place, track and ensure fulfilment of orders with our suppliers; and</li>
                                        <li class="alt-font text-black font-weight-400">carry out marketing, market research and business development.</li>
                                    </ol>
                                <p class="alt-font text-black font-weight-400">We process special categories of personal data exclusively on the basis of your consent.</p>

                                <p class="alt-font text-black"><span class="font-weight-600">Retention period: </span> 
                                    The data collected and processed within the scope of contractual relationships
                                    with us will be deleted upon completion of your request, if necessary after the expiry of contractual
                                    warranty and/or liability periods as well as statutory retention periods. Insofar as the processing of
                                    personal data is based on your consent, we will delete this data if you revoke your consent to us.
                                </p>

                                <p class="alt-font text-black font-weight-400">Please see sections 2.6 and 2.7 for more details about how we use your personal information.</p>

                                <p class="alt-font text-black"><span class="font-weight-600">Information we need to provide services to you. </span> 
                                    Please note that we need certain types of
                                    personal information so that you or your employer can provide services to us. If you do not provide us
                                    with such personal information, or if you or your employer ask us to delete it, you may no longer be
                                    able to provide services to us.
                                </p>

                                <p class="alt-font text-black font-weight-600">2.6 Universal uses for your personal information</p>
                                <p class="alt-font text-black font-weight-400">Whatever our relationship with you is, we may also collect, use and store your personal information
                                    for the following additional reasons:</p>
                                    <ul className="margin-25px-bottom alt-font text-black font-weight-400">
                                        <li>to deal with any enquiries or issues you have about how we collect, store and use your
                                            personal information, or any requests made by you for a copy of the information we hold about
                                            you;
                                        </li>
                                        <li>for internal corporate reporting, business administration, ensuring adequate insurance
                                            coverage for our business, ensuring the security of company facilities, research and
                                            development, and to identify and implement business efficiencies. We may process your
                                            personal information for these purposes where it is in our legitimate interests to do so;
                                        </li>
                                        <li>to comply with any procedures, laws and regulations which apply to us – this may include
                                            where we reasonably consider it is in our legitimate interests or the legitimate interests of
                                            others to comply, as well as where we are legally required to do so; and
                                        </li>
                                        <li>to establish, exercise or defend our legal rights – this may include where we reasonably
                                            consider it is in our legitimate interests or the legitimate interests of others, as well as where we
                                            are legally required to do so.
                                        </li>
                                    </ul>

                                    <p class="alt-font text-black font-weight-600">2.7 Further processing</p>
                                    <p class="alt-font text-black font-weight-400">Before using your personal information for any purposes which fall outside those set out in this
                                        section 2, we will undertake an analysis to establish if our new use of your personal information is
                                        compatible with the purposes set out in this section 2. Please contact us using the details in section
                                        10 if you want further information on the analysis we will undertake.
                                    </p>

                                    <p class="alt-font text-black font-weight-600">3. How and why we share your personal information with others</p>
                                    <p class="alt-font text-black font-weight-400">
                                        3.1 We may share your personal information with our group companies where it is in our legitimate
                                        interests to do so for internal administrative purposes (for example, for corporate strategy,
                                        compliance, auditing and monitoring, research and development, machine learning and quality
                                        assurance). If the processing is based upon your consent, we will only share your personal data with
                                        third parties (with the exception of processors) if you have also declared your express consent
                                        thereto; this specifically applies to the processing of special categories of personal data (such as
                                        health data).
                                    </p>

                                    <p class="alt-font text-black font-weight-400">
                                        3.2 We will share your personal information with the following third parties or categories of third
                                        parties and data processors:
                                    </p>
                                        <ol className="margin-25px-bottom alt-font text-black font-weight-400">
                                            <li>we may share anonymised and aggregated statistical information with our suppliers to
                                                demonstrate what interest there has been in any marketing campaigns we have assisted our
                                                suppliers in carrying out;
                                            </li>
                                            <li>for the purposes of performing any contracts we have agreed on or you have requested, our
                                                other service providers and sub-contractors, including payment processors, utility providers,
                                                suppliers of technical and support services, insurers, logistic providers, and cloud service
                                                providers;
                                            </li>
                                            <li>for the purposes of performing any contracts we have agreed on or you have requested, public
                                                agencies and the emergency services;
                                            </li>
                                            <li>companies that assist in our marketing, advertising and promotional activities, such as
                                                marketing automation platforms;
                                            </li>
                                            <li>upon your express consent, analytics and search engine providers that assist us in the
                                                improvement and optimisation of our website.
                                            </li>
                                        </ol>
                                    
                                    <p class="alt-font text-black font-weight-400">
                                        3.3 We will always ensure that any third parties with whom we share your personal information are
                                        subject to privacy and security obligations consistent with this privacy policy and applicable laws.
                                    </p>

                                    <p class="alt-font text-black font-weight-400">
                                        3.4 We will also disclose your personal information to third parties:
                                    </p>
                                    <ol className="margin-25px-bottom alt-font text-black font-weight-400">
                                        <li>where it is in our legitimate interests to do so to run, grow and develop our business:</li>
                                        <li>if we are under a duty to disclose or share your personal information in order to comply with
                                            any legal obligation, any lawful request from government or law enforcement officials and as
                                            may be required to meet national security or law enforcement requirements or prevent illegal
                                            activity;
                                        </li>
                                        <ul>
                                            <li>if we sell or buy any business or assets, we may disclose your personal information to
                                                the prospective seller or buyer of such business or assets;
                                            </li>
                                            <li>if substantially all of our or any of our affiliates’ assets are acquired by a third party, in
                                                which case personal information held by us will be one of the transferred assets;
                                            </li>
                                        </ul>
                                        <li>in order to enforce or apply our terms of use, our terms and conditions for customers or any
                                            other agreement or to respond to any claims, to protect our rights or the rights of a third party,
                                            to protect the safety of any person or to prevent any illegal activity; or
                                        </li>
                                        <li>to protect the rights, property, or safety of Happio, our staff, our customers or other persons.
                                            This may include exchanging personal information with other organisations for the purposes of
                                            fraud protection and credit risk reduction.
                                        </li>
                                    </ol>
                                
                                <p class="alt-font text-black font-weight-400">
                                    3.5 We may also disclose and use anonymised, aggregated reporting and statistics about users of our
                                    app, platform, website or associated services for the purpose of internal reporting or reporting to our
                                    group or other third parties, and for our marketing and promotion purposes. None of these
                                    anonymised, aggregated reports or statistics will enable our users to be personally identified.
                                </p>

                                <p class="alt-font text-black font-weight-400">
                                    3.6 Save as expressly detailed above, we will never share, sell or rent any of your personal
                                    information to any third party without notifying you and, where necessary, obtaining your consent. If
                                    you have given your consent for us to use your personal information in a particular way, but later
                                    change your mind, you should contact us and we will stop doing so.
                                </p>


                                <p class="alt-font text-black font-weight-600">4. To which countries we may transfer your personal information</p>
                                
                                <p class="alt-font text-black font-weight-400">
                                    4.1 Your personal information may be used, stored and/or accessed by staff operating outside the
                                    EEA working for us, other members of our group or suppliers. Further details on to whom your
                                    personal information may be disclosed are set out in section 3.
                                </p>
                                <p class="alt-font text-black font-weight-400">
                                    4.2 If we transfer any personal information about you to countries outside the EEA, we will take
                                    appropriate measures to ensure that the respective recipient protects your personal information
                                    adequately in accordance with this privacy policy. These measures may include the following
                                    permitted in Articles 45 and 46 of the General Data Protection Regulation:
                                </p>
                                    <ul className="margin-25px-bottom alt-font text-black font-weight-400">
                                        <li>in the case of US based entities, entering into European Commission approved standard
                                            contractual arrangements with them, or ensuring they have signed up to the EU-US Privacy
                                            Shield (see further https://www.privacyshield.gov/welcome); or</li>
                                        <li>in the case of entities based in other countries outside the EEA, entering into European
                                            Commission approved standard contractual arrangements with them.
                                        </li>
                                    </ul>

                                <p class="alt-font text-black font-weight-400">
                                    4.3 Further details on the steps we take to protect your personal information, in these cases is
                                    available from us on request by contacting us by email at hello@Happio.com at any time.
                                </p>
                                
                                <p class="alt-font text-black font-weight-600">5. Your rights</p>

                                <p class="alt-font text-black font-weight-400">
                                    5.1 You have certain rights in relation to your personal information. If you would like further
                                    information in relation to these or would like to exercise any of them, please contact us via email
                                    at hello@Happio.io at any time. You have the following rights:
                                </p>

                                    <ul className="margin-25px-bottom alt-font text-black font-weight-400">
                                        <li><span class="font-weight-600">Right of access. </span>You have a right of access to any personal information we hold about you.
                                            You can ask us for a copy of your personal information; confirmation as to whether your
                                            personal information is being used by us; details about how and why it is being used; and
                                            details of the safeguards which are in place if we transfer your information outside of the
                                            European Economic Area (“EEA”).</li>
                                        <li><span class="font-weight-600">Right to update your information. </span>You have a right to request an update to any of your
                                            personal information which is out of date or incorrect.
                                        </li>
                                        <li><span class="font-weight-600">Right to delete your information. </span>You have a right to ask us to delete any personal
                                            information which we are holding about you in certain specific circumstances. You can ask us
                                            for further information on these specific circumstances by contacting us using the details in
                                            section 10. We will pass your request onto other recipients of your personal information unless
                                            that is impossible or involves disproportionate effort. You can ask us who the recipients are,
                                            using the contact details in section 10.
                                        </li>
                                        <li><span class="font-weight-600">Right to restrict use of your information: </span>You have a right to ask us to restrict the way that
                                            we process your personal information in certain specific circumstances. You can ask us for
                                            further information on these specific circumstances by contacting us using the details in section
                                            10. We will pass your request onto other recipients of your personal information unless that is
                                            impossible or involves disproportionate effort. You can ask us who the recipients are using the
                                            contact details in section 10.
                                        </li>

                                        <li><span class="font-weight-600">Right to stop marketing: </span>You have a right to ask us to stop using your personal information
                                            for direct marketing purposes. If you exercise this right, we will stop using your personal
                                            information for this purpose.
                                        </li>
                                        <li><span class="font-weight-600">Right to data portability:</span> You have a right to ask us to provide your personal information in a
                                            structured, commonly used and machine-readable format to you or a third party provider of
                                            services named by you. This right only applies where we use your personal information on the
                                            basis of your consent or performance of a contract; and where our use of your information is
                                            carried out by automated means.
                                        </li>
                                        <li><span class="font-weight-600">Right to object: </span>You have a right to ask us to consider any valid objections which you have to
                                            our use of your personal information where we process your personal information on the basis
                                            of our or another person’s legitimate interest.
                                        </li>
                                    </ul>

                                <p class="alt-font text-black font-weight-400">
                                    5.2 We will consider all such requests and provide our response within a reasonable period (and in
                                    any event within one month of your request unless we tell you we are entitled to a longer period under
                                    applicable law). Please note, however, that certain personal information may be exempt from such
                                    requests in certain circumstances, for example if we need to keep using the information to comply
                                    with our own legal obligations or to establish, exercise or defend legal claims.
                                </p>

                                <p class="alt-font text-black font-weight-400">
                                    5.3 If an exception applies, we will tell you this when responding to your request. We may request you
                                    provide us with information necessary to confirm your identity before responding to any request you
                                    make..
                                </p>

                                <p class="alt-font text-black font-weight-600">6. Information on the processing of personal data of Children</p>
                                <p class="alt-font text-black font-weight-400">
                                    6.1 You must be aged 18 or over to purchase products or services from us. Our website and services
                                    are not directed at children and we do not knowingly collect any personal information from children
                                    without express consent from a parent or guardian.
                                </p>
                                <p class="alt-font text-black font-weight-400">
                                    6.2 If you are a child and we learn that we have inadvertently obtained personal information from you
                                    from our websites, or from any other source, then we will delete that information as soon as possible.
                                </p>
                                <p class="alt-font text-black font-weight-400">
                                    6.3 Please contact us at hello@happio.io you are aware that we may have inadvertently collected
                                    personal information from a child.
                                </p>

                                <p class="alt-font text-black font-weight-600">7. Risks and how we keep your personal information secure</p>
                                <p class="alt-font text-black font-weight-400">
                                    7.1 The main risk of our processing of your personal information is if it is lost, stolen or misused. This
                                    could lead to your personal information being in the hands of someone else who may use it
                                    fraudulently or make public, information that you would prefer to keep private.
                                </p>
                                <p class="alt-font text-black font-weight-400">
                                    7.2 For this reason, Happio is committed to protecting your personal information from loss, theft and
                                    misuse. We take all reasonable precautions to safeguard the confidentiality of your personal
                                    information, including through use of appropriate organisational and technical measures.
                                </p>
                                <p class="alt-font text-black font-weight-400">
                                    7.3 In the course of provision of your personal information to us, your personal information may be
                                    transferred over the internet. The information that you provide to us that is transferred over the
                                    internet is encrypted via methods such as HTTPS and SSL to ensure protection. Although we make
                                    every effort to protect the personal information which you provide to us, the transmission of
                                    information over the internet is not completely secure. Please keep that in mind when providing data
                                    to us using the Internet. Once we have received your personal information, we will use strict
                                    procedures and security features, to prevent unauthorized access to it.
                                </p>
                                <p class="alt-font text-black font-weight-400">
                                    7.4 Where we have given you (or where you have chosen) a password which enables you to access
                                    your online account, you are responsible for keeping this password confidential. We ask you not to
                                    share a password with anyone.
                                </p>

                                <p class="alt-font text-black font-weight-600">8. Links to other websites</p>
                                <p class="alt-font text-black font-weight-400">
                                    Our website may contain hyperlinks to websites that are not operated by us. These hyperlinks are
                                    provided for your reference and convenience only and do not imply any endorsement of the activities
                                    of such third-party websites or any association with their operators. This privacy policy only applies to
                                    the personal information that we collect or which we receive from third party sources, and we cannot
                                    be responsible for personal information about you that is collected and stored by third parties. Third
                                    party websites have their own terms and conditions and privacy policies, and you should read these
                                    carefully before you submit any personal information to these websites. We do not endorse or
                                    otherwise accept any responsibility or liability for the content of such third party websites or third party
                                </p>

                                <p class="alt-font text-black font-weight-600">9. Changes to our privacy policy</p>
                                <p class="alt-font text-black font-weight-400">
                                    We may update our privacy policy from time to time. Any changes we make to our privacy policy in
                                    the future will be posted on this page and, where appropriate, notified to you by post or email. Please
                                    check back frequently to see any updates or changes to our privacy policy.
                                </p>

                                <p class="alt-font text-black font-weight-600">10. Further questions and how to make a complaint</p>
                                <p class="alt-font text-black font-weight-400">
                                    10.1 If you have any queries or complaints about our collection, use or Retention of your personal
                                    information, or if you wish to exercise any of your rights in relation to your personal information,
                                    please contact hello@Happio.io. We will investigate and attempt to resolve any such complaint or
                                    dispute regarding the use or disclosure of your personal information.
                                </p>

                                <p class="alt-font text-black font-weight-400">
                                    10.2 In accordance with Article 77 of the General Data Protection Regulation, you may also make a
                                    complaint to the Information Commissioner’s Office, or the data protection regulator in the country
                                    where you usually live or work, or where an alleged infringement of the General Data Protection
                                    Regulation has taken place. Alternatively, you may seek a remedy through the courts if you believe
                                    your rights have been breached.
                                </p>

                                <p class="alt-font text-black font-weight-400">
                                    The practices described in this privacy policy statement are current as of 25 September 2020.
                                </p>

                            </div>

                            <div id="cookie-what-are-cookies" className="margin-75px-bottom">
                                <h4 class="alt-font text-black font-weight-600">Cookie Policy</h4>
                                <h5 class="alt-font text-black font-weight-500">What Are Cookies</h5>
                                <p class="alt-font text-black font-weight-400">
                                    As is common practice with almost all professional websites this site uses cookies, which are tiny files
                                    that are downloaded to your computer, to improve your experience. This page describes what
                                    information they gather, how we use it and why we sometimes need to store these cookies. We will
                                    also share how you can prevent these cookies from being stored however this may downgrade or
                                    &#39;break&#39; certain elements of the sites functionality.
                                </p>

                                <h5 class="alt-font text-black font-weight-500">How We Use Cookies</h5>
                                <p class="alt-font text-black font-weight-400">
                                    We use cookies for a variety of reasons detailed below. Unfortunately in most cases there
                                    are no industry standard options for disabling cookies without completely disabling the
                                    functionality and features they add to this site. It is recommended that you leave on all
                                    cookies if you are not sure whether you need them or not in case they are used to provide a
                                    service that you use.
                                </p>

                                <h6 class="alt-font text-black font-weight-500">Disabling Cookies</h6>
                                <p class="alt-font text-black font-weight-400">
                                    You can prevent the setting of cookies by adjusting the settings on your browser (see your
                                    browser Help for how to do this). Be aware that disabling cookies will affect the
                                    functionality of this and many other websites that you visit. Disabling cookies will usually
                                    result in also disabling certain functionality and features of this site. Therefore it is
                                    recommended that you do not disable cookies.
                                </p>
                                <h6 class="alt-font text-black font-weight-500">The Cookies We Set</h6>
                                <p class="alt-font text-black font-weight-400">
                                    You can prevent the setting of cookies by adjusting the settings on your browser (see your
                                    browser Help for how to do this). Be aware that disabling cookies will affect the
                                    functionality of this and many other websites that you visit. Disabling cookies will usually
                                    result in also disabling certain functionality and features of this site. Therefore it is
                                    recommended that you do not disable cookies.
                                </p>
                                <ul>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                            When you submit data to through a form such as those found on contact pages or
                                            comment forms cookies may be set to remember your user details for future
                                            correspondence.
                                        </p>
                                    </li>
                                </ul>
                                <h6 class="alt-font text-black font-weight-500">Third Party Cookies</h6>
                                <p class="alt-font text-black font-weight-400">
                                    In some special cases we also use cookies provided by trusted third parties. The following
                                    section details which third party cookies you might encounter through this site.
                                </p>
                                <ul>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                            This site uses Google Analytics which is one of the most widespread and trusted
                                            analytics solution on the web for helping us to understand how you use the site and
                                            ways that we can improve your experience. These cookies may track things such as
                                            how long you spend on the site and the pages that you visit so we can continue to
                                            produce engaging content.
                                        </p>
                                    </li>
                                </ul>
                                <p class="alt-font text-black font-weight-400">
                                    For more information on Google Analytics cookies, see the official Google Analytics page.
                                </p>
                                <ul>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                            From time to time we test new features and make subtle changes to the way that
                                            the site is delivered. When we are still testing new features these cookies may be
                                            used to ensure that you receive a consistent experience whilst on the site whilst
                                            ensuring we understand which optimisations our users appreciate the most.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                            The Google AdSense service we use to serve advertising uses a DoubleClick cookie to
                                            serve more relevant ads across the web and limit the number of times that a given
                                            ad is shown to you.
                                        </p>
                                    </li>
                                </ul>
                                <p class="alt-font text-black font-weight-400">
                                    For more information on Google AdSense see the official Google AdSense privacy FAQ.
                                </p>
                                <ul>
                                    <li>
                                        <p class="alt-font text-black font-weight-400">
                                            We use social media buttons and/or plugins on this site that allow you to connect
                                            with your social network in various ways. For these to work the following social
                                            media sites including; Facebook, Twitter and LinkedIn, will set cookies through our
                                            site which may be used to enhance your profile on their site or contribute to the
                                            data they hold for various purposes outlined in their respective privacy policies.
                                        </p>
                                    </li>
                                </ul>

                                <h6 class="alt-font text-black font-weight-500">More Information</h6>
                                <p class="alt-font text-black font-weight-400">
                                    Hopefully that has clarified things for you and as was previously mentioned if there is
                                    something that you aren&#39;t sure whether you need or not it&#39;s usually safer to leave cookies
                                    enabled in case it does interact with one of the features you use on our site. However if you
                                    are still looking for more information you can contact us through one of our preferred
                                    <br/>
                                    contact methods: <br/>
                                    Email: hello@happio.io</p>
                                   
                                
                            </div>

                            

                        </div>







                    </div>








                </div>
            </div>

        </section>


        <Footer />


    </>);
}


export { TermsPage };







/*
function TermsPage(props) {

    // console.log('props - ', props.location.query.link_id);  // li_intToServ

    const [offset, setOffset] = useState('82');

    function autoScrollToLink(link_id) {
        let element = document.getElementById(link_id);
        element.childNodes[0].click();
    }

    useEffect(() => { 
        if (props?.location?.query?.link_id) {            
            const link_id = props.location.query.link_id;
            if (link_id) {
                autoScrollToLink(link_id);
            }
        }

    }, []);
    

    return (<>

        <Header />


        <section 
            class="section wow animate__fadeIn padding-36px-tb sm-padding-30px-tb" 
            style={{visibility: 'visible', paddingTop: '82px'}}
        >
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-4 col-sm-4 md-margin-15px-bottom">
                        <div class="padding-25px-left">
                            <h6 class="alt-font text-black font-weight-500 title-18px">Media Services Terms</h6>
                            <ul class="list-style-term">

                                <li>
                                    <AnchorLink offset={offset} href='#overview'>Things</AnchorLink>
                                </li>
                                <li id="li_intToServ">
                                    <AnchorLink offset={offset} href='#intToServ'>Introduction to Our Services</AnchorLink>
                                </li>
                                <li>
                                    <AnchorLink offset={offset} href='#usingOurServ'>Using Our Services</AnchorLink>
                                </li>                                
                                <li>
                                    <AnchorLink offset={offset} href='#payTaxRef'>Payments, Taxes and Refunds</AnchorLink>
                                </li>  

                                <li id="li_cookiePolicy">
                                    <AnchorLink offset={offset} href='#cookiePolicy'>Сookie policy</AnchorLink>
                                </li>  


                                <li><a href="#">Customer Data</a></li>
                                <li><a href="#">Customer Obligations</a></li>
                                <li><a href="#">Third-Party Platforms</a></li>
                                <li><a href="#">Technical Services</a></li>
                                <li><a href="#">Warranties and Disclaimers</a></li>
                                <li><a href="#">Term and Termination</a></li>
                                <li><a href="#">Support</a></li>
                                <li><a href="#">Ownership</a></li>
                                <li><a href="#">Confidentiality</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-lg-8 col-sm-8 md-margin-25px-bottom">
                        <div class="padding-15px-left">
                            <h6 class="alt-font text-black font-weight-500 margin-20px-bottom">Media Services Terms </h6>
                                
                                <p id="overview" class="alt-font text-black font-weight-400">
                                    These terms and conditions create a contract between you and HAPPIO (the “Agreement”). Please read the Agreement carefully. To confirm your understanding and acceptance of the Agreement, click “Agree.”
                                </p>

                                <p id="intToServ" class="alt-font text-black font-weight-400">
                                    A. INTRODUCTION TO OUR SERVICES
                                </p>
                                <p class="alt-font text-black font-weight-400"> 
                                    This Agreement governs your use of HAPPIO’s services (“Services”), through which you can buy, get, license, rent or subscribe to content, Apps (as defined below), and other in-app services (collectively, “Content”). Content may be offered through the Services by HAPPIO or a third party. Our Services are available for your use in your country or territory of residence (“Home Country”). By creating an account for use of the Services in a particular country or territory you are specifying it as your Home Country. To use our Services, you need compatible hardware, software (latest version recommended and sometimes required) and Internet access (fees may apply). Our Services’ performance may be affected by these factors.
                                </p>
                                <p id="usingOurServ" class="alt-font text-black font-weight-400">
                                     B. USING OUR SERVICES
                                </p>  
                                <p class="alt-font text-black font-weight-400"> 
                                    This is using our services text. This is using our services text. This is using our services text. This is using our services text.
                                    This is using our services text. This is using our services text. This is using our services text. This is using our services text.
                                    This is using our services text. This is using our services text. This is using our services text. This is using our services text.
                                    This is using our services text. This is using our services text. This is using our services text. This is using our services text.
                                    This is using our services text. This is using our services text. This is using our services text. This is using our services text.
                                    This is using our services text. This is using our services text. This is using our services text. This is using our services text.
                                </p>                                
                                <p id="payTaxRef" class="alt-font text-black font-weight-400">
                                    PAYMENTS, TAXES, AND REFUNDS
                                </p>
                                <p class="alt-font text-black font-weight-400"> 
                                     This Agreement governs your use of HAPPIO’s services (“Services”), through which you can buy, get, license, rent or subscribe to content, Apps (as defined below), and other in-app services (collectively, “Content”). Content may be offered through the Services by HAPPIO or a third party. Our Services are available for your use in your country or territory of residence (“Home Country”). By creating an account for use of the Services in a particular country or territory you are specifying it as your Home Country. To use our Services, you need compatible hardware, software (latest version recommended and sometimes required) and Internet access (fees may apply). Our Services’ performance may be affected by these factors..
                                </p>


                                <p id="cookiePolicy" class="alt-font text-black font-weight-400">
                                    OUR СOOKIE POLICY
                                </p>  
                                <p class="alt-font text-black font-weight-400"> 
                                    This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text.
                                    This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text.
                                    This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text.
                                    This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text.
                                    This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text.
                                    This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text.
                                    This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text.
                                    This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text.
                                    This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text.
                                    This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text.
                                    This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text. This is our Cookie Policy text.
                                </p>                                    
                        </div>
                    </div>
                </div>
            </div>

        </section>


        <Footer />


    </>);
}
*/









/*
    const [inputs, setInputs] = useState({
        username: '',
        password: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const { username, password } = inputs;
    const loggingIn = useSelector(state => state.authentication.loggingIn);
    const dispatch = useDispatch();
    const location = useLocation();

    // reset login status
    useEffect(() => { 
        dispatch(userActions.logout()); 
    }, []);

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleSubmit(e) {
        e.preventDefault();

        setSubmitted(true);
        if (username && password) {
            // get return url from location state or default to home page
            const { from } = location.state || { from: { pathname: "/" } };
            dispatch(userActions.login(username, password, from));
        }
    }
*/
