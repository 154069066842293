import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../_actions';
import { Link } from 'react-router-dom';

function Footer() {

    // const [itsOk, setItsOk] = useState(false);
    const dispatch = useDispatch();

    const itsOk = useSelector(state => state.confirmation.cookies_ok);

    const itsOkButtonClick = () => {
        // setItsOk(true);
        // dispatch(userActions.checkOrgCode(orgCode));
        // { type: userConstants.ORGCODE_REQUEST, orgCode } 
        dispatch( { type: 'COOKIES_OK', cookies_ok: true } );
    }

    return (<>
        {
            !itsOk &&
            <footer className="footer">
                <div className="">
                    <div className="container"> 
                        <div className="row justify-content-center padding-two-top">
                            <div clasNames="col-12 col-sm-8 text-center">
                                <span>We use cookies to improvve your browsing experience. {' '}
                                    {/*
                                    <Link to="/confirmation" className="text-decoration-line-bottom text-green font-weight-500">Learn more</Link>
                                    
                                    <Link to={{ pathname: '/terms', query: { link_id: 'li_cookiePolicy' } }}>Terms of Service</Link>
                                    <Link to={{ pathname: '/terms', query: { link_id: 'dispute-resolution' } }} className="text-decoration-line-bottom text-green font-weight-500 mr-4">Learn more</Link>
                                    */}
                                    <Link to={{ pathname: '/terms', query: { link_id: 'li_cookie-what-are-cookies' } }} className="text-decoration-line-bottom text-green font-weight-500 mr-4">Learn more</Link>

                                    
                                    <button class="btn btn-footer submit" onClick={itsOkButtonClick} type="submit" name="submit">It`s ok</button>
                                </span>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        }

    </>);
}

export { Footer };