import { userConstants } from '../_constants';

export function registration(state = { 
    checkOrgCode: '', 
    checkOrgEmail: false,
    resentCodeAtempt: 6
}, action) {
    switch (action.type) {
        case userConstants.REGISTER_REQUEST:
            return { ...state, registering: true };
        case userConstants.REGISTER_SUCCESS:
            return state;
        case userConstants.REGISTER_FAILURE:
            return state;


        case userConstants.ORGCODE_REQUEST:
            return state;
        case userConstants.ORGCODE_SUCCESS:
            return { ...state, checkOrgCode: true };
        case userConstants.ORGCODE_FAILURE:
            return { ...state, checkOrgCode: false };
                
        case userConstants.ORGEMAIL_REQUEST:
            return state;
        case userConstants.ORGEMAIL_SUCCESS:
            return { ...state, checkOrgEmail: true };
        case userConstants.ORGEMAIL_FAILURE:
            return { ...state, checkOrgEmail: false };           
                
        case 'changeAtempt':
            // console.log('changeAtempt action - ', action);
            // debugger;

            return { ...state, resentCodeAtempt: action.att };    


        case userConstants.ASSISTANCE_REQUEST:
            return state;
        case userConstants.ASSISTANCE_SUCCESS:

            console.log('userConstants.ASSISTANCE_SUCCESS - ', action.user);

            return state;
        case userConstants.ASSISTANCE_FAILURE:
            return state;            


        default:
            return state
    }
}