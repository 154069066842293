import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

/*
        <link rel="stylesheet" type="text/css" href="css/font-icons.min.css">
        <link rel="stylesheet" type="text/css" href="css/theme-vendors.min.css">
        <link rel="stylesheet" type="text/css" href="css/style.css" />
        <link rel="stylesheet" type="text/css" href="css/responsive.css" />
    import './css/font-icons.min.css';
    import './css/theme-vendors.min.css';
    import './css/style.css';
    import './css/responsive.css';
*/

import './index.css';

import { store } from './_helpers';
import { App } from './App';

// test

// setup fake backend
// import { configureFakeBackend } from './_helpers';
// configureFakeBackend();

render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);