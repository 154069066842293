import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from '../_components';
import { Footer } from '../_components';
import { userActions } from '../_actions';

import { history } from '../_helpers';

import MaskedInput from 'react-maskedinput';

import AnchorLink from 'react-anchor-link-smooth-scroll';

import { alertActions } from '../_actions/index';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const eye = <FontAwesomeIcon icon={faEye} />;


/**
 * 
 */
function ForgotPass(props) {
    
    const dispatch = useDispatch();

    const resentCodeAtempt = useSelector(state => state.registration.resentCodeAtempt);


    const [partOfProccess, setPartOfProccess] = useState(1);


    const [correctPassword, setCorrectPassword] = useState(false);

    const [passwordShown, setPasswordShown] = useState(false);
    const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
    };    
    
    const [user, setUser] = useState({
        email: '',
        confirmationCode: '',
        card: '',
        password: '',
        codeComplete: false
    });
    
    let { email, confirmationCode } = user;
    confirmationCode = confirmationCode.replace(/ /g, '');

    console.log('confirmationCode - ', confirmationCode);
    

    const [isUserTryToSetEmail, setIsUserTryToSetEmail] = useState(false);
    const [correctEmail, setCorrectEmail] = useState(false);   


    const [submitted, setSubmitted] = useState(false);
    // const [submitted, setSubmitted] = useState(false);


    function checkEmail() {

        let isCorrectEmail = email.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/);
        
        // console.log('isCorrectEmail - ', isCorrectEmail);

        if(isCorrectEmail) {
            setCorrectEmail(true);
        } else {
            setCorrectEmail(false);
        }
    }        


    function handleChange(e) {
        // console.log('handleChange - ', e);
        // console.log('name, value - ', name, value);
        
        const { name, value } = e;
        
        setUser(user => ({ ...user, [name]: value }));

        let isUserPasswordRight = user.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/);
        if(isUserPasswordRight) {
            setCorrectPassword(true);
        } else {
            // setCorrectPassword(false);
        }        
    }


    function handleSubmit(e) {
        e.preventDefault();

        
        switch (partOfProccess) {
            case 1:
                
                if (correctEmail) {
                    
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify( { "email": email, "isRegistration": false } )
                    };
                    let resp = fetch(`https://apidev.happio.tech/api/v1/auth/email_confirmation_request`, requestOptions).then(handleResponse);
                
                    resp.then(
                        (answer) => {
                            // console.log('answer - ', answer);
                            // debugger;
                            
                            // dispatch(alertActions.success(`Email confirmation code was sent to ${email}`))
                            setPartOfProccess(2)
                        },
                        (error) => {
                            dispatch(alertActions.error(error.toString()));
                        }
                    );
                }

                break;

            case 2:
                // { "email": "string", "confirmationCode": "string" }
                if (true) {
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify( { "email": email, "confirmationCode": confirmationCode } )
                    };
                    let resp = fetch(`https://apidev.happio.tech/api/v1/auth/confirmation_code_verification`, requestOptions).then(handleResponse);
                
                    resp.then(
                        (answer) => {
                            // console.log('answer - ', answer);
                            // debugger;
                            
                            dispatch(alertActions.success(`Email verified OK`))
                            setPartOfProccess(3)
                        },
                        (error) => {
                            dispatch(alertActions.error(error.toString()));
                        }
                    );                    
                }
            
                break;


            case 3:
                // { "email": "string", "confirmationCode": "string" }
                if (true) {
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify( { "email": email, "confirmationCode": confirmationCode, newPassword: user.password } )
                    };
                    let resp = fetch(`https://apidev.happio.tech/api/v1/users/reset_password`, requestOptions).then(handleResponse);
                
                    resp.then(
                        (answer) => {
                            // console.log('answer - ', answer);
                            // debugger;
                            history.push('/login');
                            dispatch(alertActions.success(`Your password has been updated`))
                            // setPartOfProccess(3)
                        },
                        (error) => {
                            dispatch(alertActions.error(error.toString()));
                        }
                    );                    
                }
            
                break;


            default:
                break;
        }


        /*
        setSubmitted(true);
        if (user.confirmationCode && user.email && user.password && correctPassword) {
            // setModalOpen(true);
            
            // dispatch(userActions.forgotPassword(email));
        }
        */
    }

    function handleResponse(response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    // logout();
                    // location.reload(true);
                }
    
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
    
            return data;
        });
    }    



    function renderPartsofProccess() {
        switch (partOfProccess) {
            case 1:
                
                return(<>
                    <form onSubmit={handleSubmit} class="margin-30px-bottom form-width">

                        <span class="alt-font font-weight-400 margin-30px-bottom d-block title-astc text-center"> 
                            Please enter your email address and we will send you a reset password email
                        </span>

                        <input 
                            placeholder="Your email address"
                            type="text" 
                            name="email" 
                            value={user.email}
                            onBlur={(e) => {checkEmail(); setIsUserTryToSetEmail(true);}}
                            onChange={(e) => handleChange(e.target)} 
                            className={'medium-input border-radius-9px margin-10px-bottom input-hp form-control' + (submitted && !user.email ? ' is-invalid' : '')} 
                        />
                        {submitted && !user.email &&
                            <div className="invalid-feedback">Email is required</div>
                        } 
                        { isUserTryToSetEmail && !correctEmail &&
                            <div className="invalideTextForCorporativeEmail">Please provide a valid email.</div>
                        }     

                        <button class="btn btn-main btn-green w-100 margin-15px-bottom submit" type="submit" name="submit">Send Email</button>

                        <div class="form-results border-radius-5px d-none"></div>
                        <span class="alt-font font-weight-500 margin-20px-bottom d-block text-black text-center">
                            <Link to="/login" className="text-green font-weight-500">Back to Sign in!</Link>
                        </span>
                    </form>                
                </>)

            case 2:
                // <CreditCardDetails handleSuperChange={handleChange} />
                return(<>
                    <span className="alt-font font-weight-400 d-block title-email-code text-center w-350px margin-30px-bottom">
                    We've sent a 6-digit alphanumerical code to <span className="font-weight-500 text-black">{email}</span>. Please enter the code from this email
                    </span>

                    <div className="col-12 text-center">
                    <form nameName="form" onSubmit={handleSubmit}>

                        <CreditCardDetails handleSuperChange={handleChange} />

                        <div className="form-group">
                            {
                                user.codeComplete
                                ?
                                <button className="btn btn-sw submit margin-50px-bottom" type="submit" name="submit">
                                    Sent
                                </button>
                                :
                                <button className="btn btn-sw submit margin-50px-bottom" type="submit" name="submit"  disabled>
                                    code is not complete
                                </button>
                            }
                        </div>
                    </form>
                    </div>

                    {
                    (resentCodeAtempt - 1) > 0
                        ?
                        <>
                            <p>
                            <span onClick={resendCode} className="alt-font font-weight-400 d-block title-code text-center">
                                <a href="" target="_blank" class=" text-green font-weight-500">Code hasn't arrived? Resend</a>
                            </span>

                            <span className="alt-font font-weight-400 d-block title-email-code text-center w-350px margin-30px-bottom">
                                You have { resentCodeAtempt - 1 } attempt left
                            </span>
                            </p>
                        </>
                        :  
                        <p>
                        <span className="alt-font font-weight-400 d-block title-email-code text-center w-350px margin-30px-bottom">
                            You’ve reached the limit of attempts. Please try again in 1 hour
                        </span>
                        </p>
                    }                    
                </>)

            case 3:
                return(<>
                    <form nameName="form" onSubmit={handleSubmit}>

                        <div className="pass-wrapper">
                            <input 
                                placeholder="New Password"
                                type={passwordShown ? "text" : "password"} 
                                name="password" 
                                onChange={(e) => handleChange(e.target)} 
                                value={user.password} 
                                className={'medium-input border-radius-9px margin-10px-bottom input-hp form-control' + (submitted && !correctPassword ? ' is-invalid' : '')} 
                                />
                            <i style={{right: '8%'}} onClick={togglePasswordVisiblity}>{eye}</i>{" "}

                            <p class="input-text mb-3">8 characters minimum, 1 uppercase, 1 lowercase, 1 number</p>

                            {submitted && !user.password &&
                                <div className="invalid-feedback">Password is required</div>
                            }
                            {submitted && !correctPassword &&
                                <div className="invalid-feedback">Password must contein minimum eight characters, at least one uppercase letter, one lowercase letter and one number</div>
                            }

                            {
                                correctPassword
                                ?
                                <button className="btn btn-sw submit margin-50px-bottom" type="submit" name="submit">
                                    Sent
                                </button>
                                :
                                <button className="btn btn-sw submit margin-50px-bottom" type="submit" name="submit"  disabled>
                                    Enter correct password
                                </button>    
                            }
      

                        </div>     
                    </form>
                </>)

            default:
                break;
        }        
    }


    function resendCode(e) {
        e.preventDefault();

        dispatch(
            (dispatch, getState) => {
                dispatch(changeAtempt( getState().registration.resentCodeAtempt ));
            }
        );
        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify( { "email": email, "isRegistration": false } )
        };
        let resp = fetch(`https://apidev.happio.tech/api/v1/auth/email_confirmation_request`, requestOptions).then(handleResponse);
    
        resp.then(
            (answer) => {
                // console.log('answer - ', answer);
                // debugger;
                
                dispatch(alertActions.success(`Email confirmation code was sent to ${email}`))
                setPartOfProccess(2)
            },
            (error) => {
                dispatch(alertActions.error(error.toString()));
            }
        );

        function changeAtempt(attempt) { 
            let att = --attempt
            // console.log('changeAtempt------------------------------');
            // debugger;
            return { type: 'changeAtempt', att } 
        }        
    }


    return (<>

        <Header />


        <section 
            class="wow fadeIn bg-white padding-36px-tb sm-padding-30px-tb page-title-medium margin-80px-top" 
            style={{visibility: 'visible'}}
        >

            <div class="container">
                <div class="row align-items-center justify-content-center">

                    <div className="col-12 text-center">
                        <h1 className="text-black font-weight-700 text-black d-inline-block margin-30px-bottom">Forgot password</h1>
                    </div>

                    {
                        renderPartsofProccess()
                    }



                </div>
            </div>

        </section>


        <Footer />


    </>);
}


export { ForgotPass };



                        {/*
                        <CreditCardDetails handleSuperChange={handleChange} />


                        <div className="pass-wrapper">
                            <input 
                                placeholder="New Password"
                                type={passwordShown ? "text" : "password"} 
                                name="password" 
                                onChange={(e) => handleChange(e.target)} 
                                value={user.password} 
                                className={'medium-input border-radius-9px margin-10px-bottom input-hp form-control' + (submitted && !correctPassword ? ' is-invalid' : '')} 
                            />
                            <i style={{right: '8%'}} onClick={togglePasswordVisiblity}>{eye}</i>{" "}

                            <p class="input-text mb-3">8 characters minimum, 1 uppercase, 1 lowercase, 1 number</p>

                            {submitted && !user.password &&
                                <div className="invalid-feedback">Password is required</div>
                            }
                            {submitted && !correctPassword &&
                                <div className="invalid-feedback">Password must contein minimum eight characters, at least one uppercase letter, one lowercase letter and one number</div>
                            }                    
                        </div>                        
                        */}




class CreditCardDetails extends React.Component {
    state = {
      card: '',
    }
  
    _onChange = (e) => {

      // console.log('_onChange - ', e);

      let vals = e.target.value;
      this.setState({[e.target.name]: vals})
  
      let clearedVals = vals.replace(/ /g,'').replace(/_/g,'')  
      // console.log('clearedVals - ', clearedVals);
  
      if (clearedVals.length >= 6) {
        this.props.handleSuperChange({name: 'codeComplete', value: true})
        } else if (clearedVals.length <= 6) {
        this.props.handleSuperChange({name: 'codeComplete', value: false})
      }
      this.props.handleSuperChange({name: 'confirmationCode', value: vals});    
    }
  
    render() {
      return <div className="CreditCardDetails">
  
        <label>
          <MaskedInput mask="# # # # # #" name="card" autocomplete="off" placeholder="_ _ _ _ _ _" size="40" onChange={this._onChange}/>
        </label>
      </div>
    }
}





