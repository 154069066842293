import React, { useState, useEffect, Component } from 'react';
import { Link } from 'react-router-dom';
import { Header } from '../_components';
import { Footer } from '../_components';
import { useDispatch, useSelector } from 'react-redux';
import ReactCodeInput from 'react-verification-code-input';
import MaskedInput from 'react-maskedinput'

import { userActions } from '../_actions';

/**
 * Второй шаг после подтверждения Email
 * 
 * С емайлом и кодом подтверждения из первого шага(emailConfirmation) 
 * регистрируюсь на /auth/registration
 * 
 * curl -X POST "https://apidev.happio.tech/api/v1/auth/registration" -H "accept: application/json" -H "Content-Type: application/json" -d "{ \"firstName\": \"string\", \"lastName\": \"string\", \"email\": \"string\", \"code\": \"string\", \"password\": \"string\", \"confirmationCode\": \"string\"}"
 * 
    {
    "firstName": "string",
    "lastName": "string",
    "email": "string",
    "code": "string",  // это код оргпнизации и если это физик то я его не отправляю а Алекс регит его как физика
    "password": "string",
    "confirmationCode": "string"   // тут код полученный на Имеил
    }
 * 
 * @param {*} user 
 */
function RegisterPage() {
    
    const dispatch = useDispatch();

    // получаем данные с первого шага
    const confUser = useSelector(state => state.confirmation.confUser);
    // {firstName: "garry", lastName: "anan", email: "yuriy@happio.io", password: "qwerty"}
    // console.log('confUser - ', confUser);

    const [user, setUser] = useState({
      confirmationCode: '',
      codeComplete: false
    });
    const [submitted, setSubmitted] = useState(false);
    const registering = useSelector(state => state.registration.registering);
        
    const resentCodeAtempt = useSelector(state => state.registration.resentCodeAtempt);

    useEffect(() => {
      // dispatch(userActions.logout());
      setUser((user) => ({...confUser, ...user.confirmationCode}) )
    }, []);

    const handleChange = (vals) => {
      const { name, value } = vals;
      setUser(user => ({ ...user, [name]: value }));
    }

    function handleSubmit(e) {
      e.preventDefault();

      setSubmitted(true);

      if (user.confirmationCode && user.codeComplete) {
        dispatch(userActions.register(user));
      }
    }

    async function resendCode(e) {
      e.preventDefault();
      
      let data = await dispatch(userActions.emailConfirmation(user));
    }

    return (
        <>
          <Header />
          <section className="wow fadeIn bg-white padding-36px-tb sm-padding-30px-tb page-title-medium margin-80px-top" style={{visibility: 'visible'}}>
            <div className="container h-650px">
              <div className="row align-items-center justify-content-center">
                <div className="col-12 text-center">
                  <h1 className="text-black font-weight-700 text-black d-inline-block margin-30px-bottom">Email code</h1>
                </div>
                <span className="alt-font font-weight-400 d-block title-email-code text-center w-350px margin-30px-bottom">
                  We've sent a 6-digit alphanumerical code to <span className="font-weight-500 text-black">{user.email}</span>. Please enter the code from this email
                </span>

                <div className="col-12 text-center">
                  <form nameName="form" onSubmit={handleSubmit}>
                    {/*
                    <div className="form-group">
                        <label>Enter confirmation code</label>
                        <input type="text" name="confirmationCode" value={user.confirmationCode} onChange={handleChange} className={'form-control' + (submitted && !user.confirmationCode ? ' is-invalid' : '')} />
                        {submitted && !user.confirmationCode &&
                            <div className="invalid-feedback">confirmationCode is required</div>
                        }
                    </div>
                    */}
                    {/*
                      <Test2 handleSuperChange={handleChange} />
                    */}

                    <CreditCardDetails handleSuperChange={handleChange} />

                    {/*
                      // spinner-border
                      registering && <span className="spinner-border spinner-border-sm mr-1"></span>
                    */}
                    <div className="form-group">
                        {
                            user.codeComplete
                            ?
                            <button className="btn btn-sw submit margin-50px-bottom" type="submit" name="submit">
                              {registering && <span className="spinner-border-sm mr-1"></span>}
                                Register
                              </button>
                            :
                            <button className="btn btn-sw submit margin-50px-bottom" type="submit" name="submit"  disabled>
                              {registering && <span className="spinner-border-sm mr-1"></span>}
                                code is not complete
                              </button>
                        }
                    </div>
                  </form>
                </div>

                
                {
                  resentCodeAtempt > 0
                    ?
                      <>
                        <p>
                          <span onClick={resendCode} className="alt-font font-weight-400 d-block title-code text-center">
                            <a href="" target="_blank" class=" text-green font-weight-500">Code hasn't arrived? Resend</a>
                          </span>

                          <span className="alt-font font-weight-400 d-block title-email-code text-center w-350px margin-30px-bottom">
                            You have {resentCodeAtempt} attempt left
                          </span>
                        </p>
                      </>
                    :  
                    <p>
                      <span className="alt-font font-weight-400 d-block title-email-code text-center w-350px margin-30px-bottom">
                        You’ve reached the limit of attempts. Please try again in 1 hour
                      </span>
                    </p>
                }
                
              </div>
            </div>

          </section>

          <Footer />
     

            {/*
                <ReactCodeInput />
                <Test />
            */}
        </>
    );
}

export { RegisterPage };


class CreditCardDetails extends React.Component {
  state = {
    card: '',
  }

  _onChange = (e) => {
    let vals = e.target.value;
    this.setState({[e.target.name]: vals})

    let clearedVals = vals.replace(/ /g,'').replace(/_/g,'')  
    // console.log('clearedVals - ', clearedVals);

    if (clearedVals.length >= 6) {
      this.props.handleSuperChange({name: 'codeComplete', value: true})
      } else if (clearedVals.length <= 6) {
      this.props.handleSuperChange({name: 'codeComplete', value: false})
    }
    this.props.handleSuperChange({name: 'confirmationCode', value: vals});    
  }

  render() {
    return <div className="CreditCardDetails">

      <label>
        <MaskedInput mask="# # # # # #" name="card" autocomplete="off" placeholder="_ _ _ _ _ _" size="40" onChange={this._onChange}/>
      </label>
    </div>
  }
}


const STYLE = {
    width: '400px',
    margin: '60px auto'
};
  
class Test2 extends Component {
    constructor(props) {
      super(props);
  
      this.input = React.createRef();
    }
  
    handleChange = vals => {
      
      
      // user.confirmationCode.replace(/ /g,'')
      
      let clearedVals = vals.replace(/ /g,'').replace(/_/g,'')
      
      console.log('clearedVals - ', clearedVals);  

      if (vals.length >= 6) {
        // console.log('complete, ', vals);
        this.props.handleSuperChange({name: 'codeComplete', value: true})
        } else if (vals.length <= 6) {
        // console.log('empty, ', vals);
        this.props.handleSuperChange({name: 'codeComplete', value: false})
      }
      this.props.handleSuperChange({name: 'confirmationCode', value: vals});
    };
  
    render() {
      return (
        <div>
          <ReactCodeInput
            ref={this.input}
            className="registerInput"
            onChange={this.handleChange}
            onComplete={val => console.log('complete', val)}
            type="text"
          />
        </div>
      );
    }
}



/*
const CODE_LENGTH = new Array(6).fill(0);

class Test extends Component {
    input = React.createRef();

    state = {
      value: "",
    };
    render() {
      const { value } = this.state;
      const values = value.split("");
  
      return (
        <div className="App">
          <div className="wrap">
            {CODE_LENGTH.map((v, index) => {
              return(
                <div className="wrap" onClick={this.handleClick}>
                <input
                  value=""
                  ref={this.input}
                  className="input"
                  style={{
                    width: "32px",
                    top: "0px",
                    bottom: "0px",
                    left: "0px",
                  }}
                />
              </div>
              )
            })}
          </div>
        </div>
      );
    }
  }
*/  


/*


    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        // username: '',
        email: '',
        password: ''
    });


                <div className="form-group">
                    <label>First Name</label>
                    <input type="text" name="firstName" value={user.firstName} onChange={handleChange} className={'form-control' + (submitted && !user.firstName ? ' is-invalid' : '')} />
                    {submitted && !user.firstName &&
                        <div className="invalid-feedback">First Name is required</div>
                    }
                </div>
                <div className="form-group">
                    <label>Last Name</label>
                    <input type="text" name="lastName" value={user.lastName} onChange={handleChange} className={'form-control' + (submitted && !user.lastName ? ' is-invalid' : '')} />
                    {submitted && !user.lastName &&
                        <div className="invalid-feedback">Last Name is required</div>
                    }
                </div>
                <div className="form-group">
                    <label>Email</label>
                    <input type="text" name="email" value={user.email} onChange={handleChange} className={'form-control' + (submitted && !user.email ? ' is-invalid' : '')} />
                    {submitted && !user.email &&
                        <div className="invalid-feedback">Email is required</div>
                    }
                </div>                
                <div className="form-group">
                    <label>Password</label>
                    <input type="password" name="password" value={user.password} onChange={handleChange} className={'form-control' + (submitted && !user.password ? ' is-invalid' : '')} />
                    {submitted && !user.password &&
                        <div className="invalid-feedback">Password is required</div>
                    }
                </div>
                <div className="form-group">
                    <button className="btn btn-primary">
                        {registering && <span className="spinner-border spinner-border-sm mr-1"></span>}
                        Register
                    </button>
                    <Link to="/login" className="btn btn-link">Cancel</Link>
                </div>
*/