import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../_actions';

import { Header } from '../_components';
import { Footer } from '../_components';
import { Modals } from '../_components';
import GoogleLogin from 'react-google-login';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const eye = <FontAwesomeIcon icon={faEye} />;





/**
 * 
 */
function EmailConfPage() {
    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        
        // ------------------------------
        prevOrgCode: '',
        orgCode: '',
        isOrgCodeValid: false,
        // ------------------------------

        email: '',
        password: ''
    });

    let { orgCode, email } = user;
    // console.log('orgCode - ', orgCode);

    const registering = useSelector(state => state.registration.registering);
    const checkOrgCodeGlobal = useSelector(state => state.registration.checkOrgCode);
    const checkOrgEmailGlobal = useSelector(state => state.registration.checkOrgEmail);
    
    const allertMessage = useSelector(state => state.alert.message);

    // console.log(allertMessage);
    // debugger;
    

    // console.log('checkOrgCodeGlobal - ', checkOrgCodeGlobal);
    // console.log('!checkOrgEmailGlobal - ', !checkOrgEmailGlobal);

    if (checkOrgCodeGlobal && !checkOrgEmailGlobal) {
        
        // debugger;
    }

    const dispatch = useDispatch();
    
    const [submitted, setSubmitted] = useState(false);
    const [correctPassword, setCorrectPassword] = useState(false);


    const [isUserTryToSetEmail, setIsUserTryToSetEmail] = useState(false);
    const [correctEmail, setCorrectEmail] = useState(false);

    const [ifItsNotAbusinesAccount, setIfItsNotAbusinesAccount] = useState(true);


    const [passwordShown, setPasswordShown] = useState(false);
    const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
    };
  

    // console.log('correctEmail - ', correctEmail);


    // reset login status
    useEffect(() => {
        // dispatch(userActions.logout());

        if (orgCode.length == 6) {
            // console.log('useEffect orgCode - ', orgCode);
            dispatch(userActions.checkOrgCode(orgCode));
            // console.log('result - ', result);
        }
    }, [user.orgCode]);


    const responseGoogle = (response) => {

        dispatch(userActions.googleLogin(response));
        if (response) {
            // console.log('responseGoogle if');
        }
    }


    function checkEmail() {

        let isCorrectEmail = user.email.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/);
        
        // console.log('isCorrectEmail - ', isCorrectEmail);

        if(isCorrectEmail) {
            setCorrectEmail(true);
        } else {
            setCorrectEmail(false);
        }
    }


    function handleChange(e) {
        
        // console.log('handleChange');
        
        const { name, value } = e.target;
        setUser(user => ({ ...user, [name]: value }));
        
        let isUserPasswordRight = user.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/);
        if(isUserPasswordRight) {
            setCorrectPassword(true);
        } else {
            // setCorrectPassword(false);
        }

        setSubmitted(false);
        // checkEmail();
    }

    function handleOnBlurOnCode(e) {
        if (checkOrgCodeGlobal && user.email.length > 7) {
            dispatch(userActions.checkOrgEmail(email, orgCode));
        }

        if (!checkOrgCodeGlobal && user.orgCode.length !== 6 && user.orgCode.length > 0) {
            setIfItsNotAbusinesAccount(false);
        } else {
            setIfItsNotAbusinesAccount(true);
        }
    }

    function handleOnBlur(e) {
        if (checkOrgCodeGlobal && user.email.length > 7) {
            dispatch(userActions.checkOrgEmail(email, orgCode));
        }

        checkEmail();
        setIsUserTryToSetEmail(true)
    }

    const [userAgreeTerms, setUserAgreeTerms] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [isModalOpeningBefore, setIsModalOpeningBefore] = useState(false);

    function handleSubmit(e) {
        e.preventDefault();

        setSubmitted(true);
        if (user.firstName && user.lastName && user.email && user.password && correctPassword) {
            // dispatch(userActions.emailConfirmation(user));
            setModalOpen(true);
            setIsModalOpeningBefore(true);
        }
    }

    if (submitted && userAgreeTerms) {
        dispatch(userActions.emailConfirmation(user));
    }


    if (!modalOpen && isModalOpeningBefore) {
        setSubmitted(false);
    }


    // console.log( !(checkOrgCodeGlobal == ''), checkOrgCodeGlobal);
    // debugger;

    return (<>

        {
           submitted && user.firstName && user.lastName && user.email && user.password && correctPassword &&
                <Modals setUserAgreeTerms={setUserAgreeTerms} />
        }
        <Header />
        <section className="wow fadeIn bg-white padding-36px-tb sm-padding-30px-tb page-title-medium margin-80px-top" style={{visibility: 'visible'}}> 
            <div class="container h-650px">
                <div className="row align-items-center justify-content-center">
                    <div className="col-12 text-center">
                        <h1 className="text-black font-weight-700 text-black d-inline-block margin-30px-bottom">Sign up</h1>
                    </div>


                    <form className="margin-30px-bottom form-width" onSubmit={handleSubmit}>


                        {/*
                        <input class="medium-input border-radius-9px margin-10px-bottom input-hp" type="text" name="name" placeholder="First name" />
                        */}
                        <input 
                            placeholder="First name"
                            type="text" 
                            name="firstName" 
                            value={user.firstName} 
                            onChange={handleChange} 
                            className={'medium-input border-radius-9px margin-10px-bottom input-hp form-control' + (submitted && !user.firstName ? ' is-invalid' : '')} 
                        />
                        {submitted && !user.firstName &&
                            <div className="invalid-feedback">First Name is required</div>
                        }

                        {/*
                        <input class="medium-input border-radius-9px margin-10px-bottom input-hp" type="text" name="name" placeholder="Last name" />
                        */}
                        <input 
                            placeholder="Last name"
                            type="text" 
                            name="lastName" 
                            value={user.lastName} 
                            onChange={handleChange} 
                            className={'medium-input border-radius-9px margin-10px-bottom input-hp form-control' + (submitted && !user.lastName ? ' is-invalid' : '')} 
                        />
                        {submitted && !user.lastName &&
                            <div className="invalid-feedback">Last Name is required</div>
                        }                    

                        {/*
                        <input class="medium-input border-radius-9px margin-5px-bottom input-hp" type="text" name="name" placeholder="Organization code" />
                        <p class="input-text">Leave empty if not a business account</p>
                        */}

                        {/*
                            className={'medium-input border-radius-9px margin-10px-bottom input-hp form-control' + (user.orgCode ? ' is-invalid' : '')} 
                            : <p className="input-text">Leave empty if not a business account</p>
                            : <p className={ !(submitted && !checkOrgCodeGlobal && user.orgCode.length !== 6) ? "input-text" : "invalideTextForCorporativeEmail" }>Leave empty if not a business account</p>
                        */}
                        
                        <input 
                            type="text" 
                            name="orgCode"  
                            placeholder="Organization code" 
                            value={user.orgCode} 
                            onChange={handleChange} 
                            onBlur={handleOnBlurOnCode}
                            className={'medium-input border-radius-9px margin-10px-bottom input-hp form-control' + (user.orgCode.length == 6 && !checkOrgCodeGlobal ? ' is-invalid' : '')} 
                        />
                        {/* submitted && !checkOrgCodeGlobal && user.orgCode.length !== 6 &&
                            <div className="invalideTextForCorporativeEmail">Please leave empty if not a business account.</div>
                        */}                            
                        {
                            user.orgCode.length == 6 && !checkOrgCodeGlobal
                                ? <div className="invalid-feedback">The code is not valid. Please try again or ask for help.</div>
                                : <p className={ ifItsNotAbusinesAccount ? "input-text" : "invalideTextForCorporativeEmail" }>Leave empty if not a business account</p>
                        }
                    
                        

                        {/*
                            value={user.email} 
                                const checkOrgCodeGlobal = useSelector(state => state.registration.checkOrgCode);
                                const checkOrgEmailGlobal = useSelector(state => state.registration.checkOrgEmail);

                                user.email.length > 7 && checkOrgCodeGlobal && !checkOrgEmailGlobal &&  allertMessage == 'Invalid organization code for this email' &&
                        */}
                        <input 
                            placeholder="Your email address"
                            type="text" 
                            name="email" 
                            value={user.email} 
                            onChange={handleChange} 
                            onBlur={handleOnBlur} 
                            className={'medium-input border-radius-9px margin-10px-bottom input-hp form-control' + (submitted && !user.email ? ' is-invalid' : '')} 
                        />
                        {submitted && !user.email &&
                            <div className="invalid-feedback">Email is required</div>
                        }
                        { user.email.length > 7 && checkOrgCodeGlobal && !checkOrgEmailGlobal &&
                            <div className="invalideTextForCorporativeEmail">This email doesn't match your organization. Please use your business email. For example, joe@company.com</div>
                        }
                        { isUserTryToSetEmail && !correctEmail &&
                            <div className="invalideTextForCorporativeEmail">Please provide a valid email.</div>
                        }                                    

                        {/*
                            value='Qwerty123' 
                        */}
                        <div className="pass-wrapper">
                            <input 
                                placeholder="Password"
                                type={passwordShown ? "text" : "password"} 
                                name="password" 
                                onChange={handleChange} 
                                value={user.password} 
                                className={'medium-input border-radius-9px margin-10px-bottom input-hp form-control' + (submitted && !correctPassword ? ' is-invalid' : '')} 
                            />
                            <i style={{right: '8%'}} onClick={togglePasswordVisiblity}>{eye}</i>{" "}

                            <p class="input-text mb-3">8 characters minimum, 1 uppercase, 1 lowercase, 1 number</p>

                            {submitted && !user.password &&
                                <div className="invalid-feedback">Password is required</div>
                            }
                            {submitted && !correctPassword &&
                                <div className="invalid-feedback">Password must contein minimum eight characters, at least one uppercase letter, one lowercase letter and one number</div>
                            }                    
                        </div>
                        
                        {/*
                        <input type="hidden" name="redirect" value="" />
                        */}
                        <button className="btn btn-main btn-green w-100 margin-15px-bottom submit" type="submit" name="submit">
                            {/* registering && <span className="spinner-border spinner-border-sm mr-1"></span> */}
                            Sing up
                        </button>
                        <div className="form-results border-radius-5px d-none"></div>

                        <span className="alt-font font-weight-500 margin-20px-bottom d-block text-black text-center">Have an account? {' '}
                            {/*
                            <a href="#" target="_blank" class="text-green font-weight-500">
                                Sign in!
                            </a>
                            */}
                            <Link to="/login" className="text-green font-weight-500">Sign in!</Link>
                        </span>

                        <span className="alt-font margin-10px-bottom d-block text-black text-center title-or">OR</span>  

                        <div className="googleButtonWraper">
                            <GoogleLogin
                                clientId="827993157011-9nnqufjk99sh2e4dt0l0ui44ro2o2hmi.apps.googleusercontent.com"
                                buttonText="Sign up with Google"
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                cookiePolicy={'single_host_origin'}
                            />
                        </div>

                        <span className="alt-font font-weight-400 margin-15px-bottom d-block text-center title-terms terms-link">By logging in, you are agreeing to the  {' '}
                            {/*
                            <Link to="/terms" className="text-green font-weight-500">Terms of Service</Link>
                            */}
                            <Link to={{ pathname: '/terms', query: { link_id: 'li_using-the-happio-service' } }}>Terms of Service</Link>
                        </span>

                    </form>
                </div>
                
            </div>
        </section>
        <Footer />
        </>
    );
}

export { EmailConfPage };



/*
    <input type="password" name="password" value={user.password} onChange={handleChange} className={'form-control' + (submitted && !correctPassword ? ' is-invalid' : '')} />
*/

/*

            <div className="col-lg-8 offset-lg-2">
                <h2>Sign up</h2>
                <form name="form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>First Name</label>
                        <input type="text" name="firstName" value={user.firstName} onChange={handleChange} className={'form-control' + (submitted && !user.firstName ? ' is-invalid' : '')} />
                        {submitted && !user.firstName &&
                            <div className="invalid-feedback">First Name is required</div>
                        }
                    </div>
                    <div className="form-group">
                        <label>Last Name</label>
                        <input type="text" name="lastName" value={user.lastName} onChange={handleChange} className={'form-control' + (submitted && !user.lastName ? ' is-invalid' : '')} />
                        {submitted && !user.lastName &&
                            <div className="invalid-feedback">Last Name is required</div>
                        }
                    </div>

                    <div className="form-group">
                        <label>Organization code</label>
                        <input type="text" name="orgCode" value={user.orgCode} onChange={handleChange} className={'form-control'} />
                        <div className="small-input-feed">Leave empty if note a business account (ZZEV6R) (jenniferhaney@hotmail.com)</div>
                    </div>                    


                    <div className="form-group">
                        <label>Email</label>
                        <input type="text" name="email" value={user.email} onChange={handleChange} onBlur={handleOnBlur} className={'form-control' + (submitted && !user.email ? ' is-invalid' : '')} />
                        {submitted && !user.email &&
                            <div className="invalid-feedback">Email is required</div>
                        }
                    </div>

                    <div className="form-group">
                        <label>Password</label>

                        <div className="pass-wrapper">
                            <input 
                                type={passwordShown ? "text" : "password"} 
                                name="password" 
                                value={user.password} 
                                onChange={handleChange} 
                                className={'form-control' + (submitted && !correctPassword ? ' is-invalid' : '')} 
                                />
                            <i style={{right: '8%'}} onClick={togglePasswordVisiblity}>{eye}</i>{" "}
                        
                        {submitted && !user.password &&
                            <div className="invalid-feedback">Password is required</div>
                        }
                        {submitted && !correctPassword &&
                            <div className="invalid-feedback">Password must contein minimum eight characters, at least one uppercase letter, one lowercase letter and one number</div>
                        }                    
                        </div>
                    </div>
                    <div className="form-group">
                        <button className="btn btn-primary">
                            {registering && <span className="spinner-border spinner-border-sm mr-1"></span>}
                            Sign up
                        </button>
                        <Link to="/login" className="btn btn-link">Cancel</Link>
                    </div>
                </form>
            </div>
            <GoogleLogin
                clientId="827993157011-9nnqufjk99sh2e4dt0l0ui44ro2o2hmi.apps.googleusercontent.com"
                buttonText="Login"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
            />

*/

/*


    if( user.orgCode.length == 6 && !checkOrgCodeGlobal ) {

        console.log('Organization code is not valid');
    }                            
                        <input 
                            type="text" 
                            name="orgCode"  
                            placeholder="Organization code" 
                            value={user.orgCode} 
                            onChange={handleChange} 
                            className={'medium-input border-radius-9px margin-10px-bottom input-hp form-control' + (user.orgCode.length == 6 && !checkOrgCodeGlobal ? ' is-invalid' : '')} 
                        />
                        {
                            user.orgCode.length == 6 && !checkOrgCodeGlobal
                            ? <div className="invalid-feedback">Organization code is not valid</div>
                            : <p className="input-text">Leave empty if not a business account</p>
                        }

   if( user.orgCode.length == 6 && !checkOrgCodeGlobal ) {
        // console.log('checkOrgCodeGlobal - ', checkOrgCodeGlobal);
        
        console.log('Organization code is not valid');
        debugger;
    } else  {
        console.log('.....');
        debugger
    }

*/


/*
{
  "hostedDomain": "string",
  "userID": "string",
  "authentication": {
    "accessTokenExpirationDate": 0,    // expires_at
    "refreshToken": "string",
    "idToken": "string",         // id_token
    "clientID": "string",
    "accessToken": "string",
    "idTokenExpirationDate": 0
  },
  "email": "string",
  "givenName": "string",
  "name": "string",
  "familyName": "string",
  "profile": {}
}
*/


    /*
Ca: "111524638729856592344"
Es: bx {
    JR: "111524638729856592344"
    kt: "akivka@gmail.com"
    nT: "Akivka"
    pR: "Ch"
    sd: "Akivka Ch"
    vI: "https://lh3.googleusercontent.com/a-/AOh14GjKL1O8Jt-gChjRkM0RpBmb3Z9bsw3zq-JkAJQyoQ=s96-c"
}
accessToken: "ya29.A0AfH6SMA3jCuZqO4r7DLJK3sMHxvflEibQyvnR1wJP-I5yaM4yKgeNbrYA_laRr084-WA9v68yaxc2ZLQl6WnZvWM5SuZZQPBtmjGPi7VxLq378gw259gIgSjORS9JCTLP3Jj4gSBcO1AaYJo0kJNJLZfROWi"
googleId: "108988013732030576108"
profileObj: {
    email: "yuriy@happio.io"
    familyName: "Ananchenko "
    givenName: "Yuriy"
    googleId: "108988013732030576108"
    imageUrl: "https://lh4.googleusercontent.com/-lB7UQ6ZBmtU/AAAAAAAAAAI/AAAAAAAAAAA/AMZuuclZSTSe3TB8KW4tMlTzUdztMF1Qog/s96-c/photo.jpg"
    name: "Yuriy Ananchenko"
}
tokenId: "eyJhbGciOiJSUzI1NiIsImtpZCI6ImZkYjQwZTJmOTM1M2M1OGFkZDY0OGI2MzYzNGU1YmJmNjNlNGY1MDIiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiODI3OTkzMTU3MDExLTlubnF1ZmprOTlzaDJlNGR0MGwwdWk0NHJvMm8yaG1pLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiODI3OTkzMTU3MDExLTlubnF1ZmprOTlzaDJlNGR0MGwwdWk0NHJvMm8yaG1pLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTA4OTg4MDEzNzMyMDMwNTc2MTA4IiwiaGQiOiJoYXBwaW8uaW8iLCJlbWFpbCI6Inl1cml5QGhhcHBpby5pbyIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhdF9oYXNoIjoiQUZza1dRck96c1JnT1hQMnZqVGgxdyIsIm5hbWUiOiJZdXJpeSBBbmFuY2hlbmtvIiwicGljdHVyZSI6Imh0dHBzOi8vbGg0Lmdvb2dsZXVzZXJjb250ZW50LmNvbS8tbEI3VVE2WkJtdFUvQUFBQUFBQUFBQUkvQUFBQUFBQUFBQUEvQU1adXVjbFpTVFNlM1RCOEtXNHRNbFR6VWR6dE1GMVFvZy9zOTYtYy9waG90by5qcGciLCJnaXZlbl9uYW1lIjoiWXVyaXkiLCJmYW1pbHlfbmFtZSI6IkFuYW5jaGVua28gIiwibG9jYWxlIjoiZW4tR0IiLCJpYXQiOjE2MTM2ODQ2ODksImV4cCI6MTYxMzY4ODI4OSwianRpIjoiMjZiZWQ5MzBkMjE2MjExNjVjMWE4MzVjODk1YTAxOGVlY2JmN2IwYyJ9.WDCqk32ZHsxnalwHLFfWUKW6HAWSbOgQH_Pm-Z9hQ29n0Ozm7vKOg0_MwMtSlw0npKmFLwbWdWedTR8blFnRtzXbtQBy_PWVvIBTbcMnt9YmsApZxPl8k4xGXk_fAjnWb-i0mtiLuSOu3sMtWrlVFgQEBNvg_khwOP7J5tqP8o6n4LfT32PN122u4-KHPjaaU4LK988ZnLpuB5LHi_KEclX5KzFfzZFg3NPxVQYwHWbyY7q286pO5Gpv87VHUXqEZwaREK8LmPRVTUlLwmY0PhaN7TiYFIKcBIRDmbUzPNoaKyR0uzeguKBJX836v9U5_OsNg-7kYJVrUQUrqPOufw"
tokenObj: {
    access_token: "ya29.A0AfH6SMA3jCuZqO4r7DLJK3sMHxvflEibQyvnR1wJP-I5yaM4yKgeNbrYA_laRr084-WA9v68yaxc2ZLQl6WnZvWM5SuZZQPBtmjGPi7VxLq378gw259gIgSjORS9JCTLP3Jj4gSBcO1AaYJo0kJNJLZfROWi"
    expires_at: 1613688288141
    expires_in: 3599
    first_issued_at: 1613684689141
    id_token: "eyJhbGciOiJSUzI1NiIsImtpZCI6ImZkYjQwZTJmOTM1M2M1OGFkZDY0OGI2MzYzNGU1YmJmNjNlNGY1MDIiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiODI3OTkzMTU3MDExLTlubnF1ZmprOTlzaDJlNGR0MGwwdWk0NHJvMm8yaG1pLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiODI3OTkzMTU3MDExLTlubnF1ZmprOTlzaDJlNGR0MGwwdWk0NHJvMm8yaG1pLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTA4OTg4MDEzNzMyMDMwNTc2MTA4IiwiaGQiOiJoYXBwaW8uaW8iLCJlbWFpbCI6Inl1cml5QGhhcHBpby5pbyIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhdF9oYXNoIjoiQUZza1dRck96c1JnT1hQMnZqVGgxdyIsIm5hbWUiOiJZdXJpeSBBbmFuY2hlbmtvIiwicGljdHVyZSI6Imh0dHBzOi8vbGg0Lmdvb2dsZXVzZXJjb250ZW50LmNvbS8tbEI3VVE2WkJtdFUvQUFBQUFBQUFBQUkvQUFBQUFBQUFBQUEvQU1adXVjbFpTVFNlM1RCOEtXNHRNbFR6VWR6dE1GMVFvZy9zOTYtYy9waG90by5qcGciLCJnaXZlbl9uYW1lIjoiWXVyaXkiLCJmYW1pbHlfbmFtZSI6IkFuYW5jaGVua28gIiwibG9jYWxlIjoiZW4tR0IiLCJpYXQiOjE2MTM2ODQ2ODksImV4cCI6MTYxMzY4ODI4OSwianRpIjoiMjZiZWQ5MzBkMjE2MjExNjVjMWE4MzVjODk1YTAxOGVlY2JmN2IwYyJ9.WDCqk32ZHsxnalwHLFfWUKW6HAWSbOgQH_Pm-Z9hQ29n0Ozm7vKOg0_MwMtSlw0npKmFLwbWdWedTR8blFnRtzXbtQBy_PWVvIBTbcMnt9YmsApZxPl8k4xGXk_fAjnWb-i0mtiLuSOu3sMtWrlVFgQEBNvg_khwOP7J5tqP8o6n4LfT32PN122u4-KHPjaaU4LK988ZnLpuB5LHi_KEclX5KzFfzZFg3NPxVQYwHWbyY7q286pO5Gpv87VHUXqEZwaREK8LmPRVTUlLwmY0PhaN7TiYFIKcBIRDmbUzPNoaKyR0uzeguKBJX836v9U5_OsNg-7kYJVrUQUrqPOufw"
    idpId: "google"
    login_hint: "AJDLj6JUa8yxXrhHdWRHIV0S13cAwFoHMeM4wxN48YCbJe97SYXMvkkfSWp3zNSA2HAIY_DxDYkr5LUOWiZXBuDHXgvGrpRB_w"
    scope: "email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid"
    session_state: {extraQueryParams: {…}}
    token_type: "Bearer"
}
uc: {
    access_token: "ya29.A0AfH6SMBuID_aQrEzGSv1e1CCvWQvhNjICZDOAxGmUKJTHaRGdpGkBSGOCkmSc_NGYSDPYgAKBwK6K26zg9gb-0XhxrG1xdDkEWJjVGNh_gDdzXdjM6kuGAoxp-vlxU3mwpMbrFuyJuCT3Os0I-DZXKG9-qTG"
    expires_at: 1613688384464
    expires_in: 3599
    first_issued_at: 1613684785464
    id_token: "eyJhbGciOiJSUzI1NiIsImtpZCI6ImZkYjQwZTJmOTM1M2M1OGFkZDY0OGI2MzYzNGU1YmJmNjNlNGY1MDIiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiODI3OTkzMTU3MDExLTlubnF1ZmprOTlzaDJlNGR0MGwwdWk0NHJvMm8yaG1pLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiODI3OTkzMTU3MDExLTlubnF1ZmprOTlzaDJlNGR0MGwwdWk0NHJvMm8yaG1pLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTExNTI0NjM4NzI5ODU2NTkyMzQ0IiwiZW1haWwiOiJha2l2a2FAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImF0X2hhc2giOiItVGZjbmtYeDRld0hpbER4SjlmQjhnIiwibmFtZSI6IkFraXZrYSBDaCIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS0vQU9oMTRHaktMMU84SnQtZ0NoalJrTTBScEJtYjNaOWJzdzN6cS1Ka0FKUXlvUT1zOTYtYyIsImdpdmVuX25hbWUiOiJBa2l2a2EiLCJmYW1pbHlfbmFtZSI6IkNoIiwibG9jYWxlIjoicnUiLCJpYXQiOjE2MTM2ODQ3ODUsImV4cCI6MTYxMzY4ODM4NSwianRpIjoiZGY4MmNjOGM3NzUzNTgyN2FlNjQxZTQ3ZWYzOWViMDE1MTc5NjlkNSJ9.SxGp_-Pzzd2CjkzlByMAv6OVvd9jJMbNJVdPfpO_IWSiSakMSqfE_gbmBlEV88Ha4rsa3O_SIk3dsYhg7Fa2lv_PYD0HG_evnrZ2j2zYMJj4Dvrel5jJV9UaVPOW2m252n1KrcujRjtg6EBspj647bG9YnPC3X83YwVSmNZrsnUyx32fkRux57k2U2zscSxMkiDsc4VVUu5MZuXeiJ8ZQaGIxqZUbNex4PaUt73tEGsCKczJg5YFeTgmtN0bBOOA2e9Eec6iH8PPHSdI2XocDPYN7c6f4QDYYZczePtHuTu5NHg1vQYAgV83zEDTwt3CmJQkUNZVlXdONjgo6uRb5w"
    idpId: "google"
    login_hint: "AJDLj6JUa8yxXrhHdWRHIV0S13cAAigG32ZDB8RdMDCxNVM_6ndOGO6fauWWNiPf15JtkvrdXYR3EH3lBs7K6Cyo3uMjGpzSIQ"
    scope: "email profile openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email"
    session_state: {extraQueryParams: {…}}
    token_type: "Bearer"
}
__proto__: Object    
    */