import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';

import { Player, BigPlayButton, PosterImage } from 'video-react';
import 'video-react/dist/video-react.css';


/**
 * 
 * <div className={`alert ${alert.type}`} style={{ marginTop: '85px' }}>{alert.message}</div>
 */
function AllertModal({alert}) {
    const [modalShow, setModalShow] = React.useState(true);

    // alert = {type: "alert-success", message: "We have received your assistance request. We will be in touch with you via email as soon as we can"}
    // console.log(alert.type);
    // debugger;

    if (alert.type == "alert-success") {
      return (
        <>
          {/*
          <Button variant="primary" onClick={() => setModalShow(true)}>
            Launch vertically centered modal
          </Button>
          */}
    
          <SuccessAlertModal
            show={modalShow}
            alertMessage={alert.message}
            onHide={(e) => {
              e.preventDefault();
              setModalShow(false);
              // setVideoModalOpen(false);
            }
          }
          />
        </>
      );
    } else {
      return (
        <>
          {/*
          <Button variant="primary" onClick={() => setModalShow(true)}>
            Launch vertically centered modal
          </Button>
          */}
    
          <ErrorAlertModal
            show={modalShow}
            alertMessage={alert.message}
            onHide={(e) => {
              e.preventDefault();
              setModalShow(false);
              // setVideoModalOpen(false);
            }
          }
          />
        </>
      );
    }

    
}

export { AllertModal };


function SuccessAlertModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div class="col-12 col-lg-12 md-margin-25px-bottom padding-30px-lr padding-40px-tb">
          <div class="container">
              <div class="row align-items-center justify-content-center">
                <section 
                  class="section wow animate__fadeIn padding-36px-tb sm-padding-30px-tb" 
                  style={{visibility: 'visible', paddingTop: '82px'}}
                >
                  <div class="container">
                      <div class="row justify-content-center">
                          
                          
                          <div class="col-md-12 text-center">
                              <div class="margin-50px-bottom">
                                  <img src="./images/icons/success.svg" data-at2x="./images/icons/success.svg" class="default-logo" alt=""></img>
                              </div>
                              <h6 class="alt-font text-black font-weight-700 title-extra-small">Thank you</h6>
                              <p class="subtitle-sw margin-65px-bottom">{props.alertMessage}</p>
                              <button class="btn btn-sw submit" type="submit" name="submit" onClick={ (e) => props.onHide(e) }>Done</button>
                          </div> 
                      </div>
                  </div>
                </section>
              </div>
          </div>
        </div>
      </Modal>
    );
}


function ErrorAlertModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div class="col-12 col-lg-12 md-margin-25px-bottom padding-30px-lr padding-40px-tb">
        <div class="container">
            <div class="row align-items-center justify-content-center">
              <section 
                class="section wow animate__fadeIn padding-36px-tb sm-padding-30px-tb" 
                style={{visibility: 'visible', paddingTop: '82px'}}
              >
                <div class="container">
                    <div class="row justify-content-center">
                        
                        
                        <div class="col-md-12 text-center">
                            <div class="margin-50px-bottom">
                                <img src="./images/icons/went-wrong.svg" data-at2x="./images/icons/went-wrong.svg" class="default-logo" alt=""></img>
                            </div>
                            <h6 class="alt-font text-black font-weight-700 title-extra-small">{props.alertMessage}</h6>
                            <p class="subtitle-sw margin-65px-bottom">Please try again</p>
                            <button onClick={ (e) => props.onHide(e) } class="btn btn-sw submit" type="submit" name="submit">Done</button>
                        </div> 
                    </div>
                </div>
              </section>
            </div>
        </div>
      </div>
    </Modal>
  );
}









/*

function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div class="col-12 col-lg-12 md-margin-25px-bottom padding-30px-lr padding-40px-tb">

          <div class="container">
              <div class="row align-items-center justify-content-center">

                <div className="col-12 text-center">
                    <h4 className="text-black font-weight-700 text-black d-inline-block margin-30px-bottom">Welcome to Happio</h4>
                </div>

                <p class="alt-font font-weight-400" style={{lineHeight: '20px'}}> 
                  Every day, our editors take you behind the scenes of your mental health programs. Stay in touch 
                  with Happio when you need to decrease the stress.
                </p>                   



                <p onClick={ (e) => props.onHide(e) } style={{ cursor: 'pointer' }} class="alt-font font-weight-500 margin-20px-bottom margin-20px-top d-block text-black text-center text-green">
                  Skip {'          '}
                </p>                  

              </div>
          </div>

        </div>
      </Modal>
    );
}

*/





                /*
                <div style={{width: '100%', margin: 'auto', borderRadius: '20%'}}>
                  <Player 
                      src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
                      poster="./images/video_logo.png"
                      fluid={true}
                  >
                  </Player>
                </div>

                  <span class="alt-font font-weight-500 margin-20px-bottom d-block text-black text-center">
                  New here?  <a class="text-green font-weight-500" href="/confirmation">Sign up!</a></span>

                <br/>
                <p class="alt-font font-weight-500 margin-20px-bottom d-block text-black text-center text-green" style={{display: 'none'}}>
                  Skip {'          '}
                </p>
                */



/*
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Modal heading
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Centered Modal</h4>
          <p>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
            consectetur ac, vestibulum at eros.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
*/