import { userConstants } from '../_constants';

export function confirmation(state = {cookies_ok: false}, action) {

    // console.log('confirmation action -> ', action); // action.user: {firstName: "garry2", lastName: "anan2", email: "yuriy@happio.io", password: "qwerty"}S
    // debugger

    switch (action.type) {
        case userConstants.CONFIRMATION_REQUEST:
            return { ...state, registering: true };
        case userConstants.CONFIRMATION_SUCCESS:
            return {
                ...state,
                confUser: action.user
            };

        /*
        case 'changeAtempt':
            console.log('changeAtempt');
            debugger;
            
            return { ...state, resentCodeAtempt: state.resentCodeAtempt-- };  
        */
       case 'COOKIES_OK':
            return { 
                ...state, 
                cookies_ok: true,
            };               

        case userConstants.CONFIRMATION_FAILURE:
            return StaticRange;
        default:
            return state
    }
}