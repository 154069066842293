import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';

function Header(props) {

    let locationUrlArr = window.location.href.split('/');
    let lastSimbol = locationUrlArr[locationUrlArr.length - 1]
    
    // console.log('lastSimbol - ', lastSimbol);

    return (<>
        {/* <header class="sticky"> */}
        <header className="">
            <nav className="navbar top-space navbar-expand-lg navbar-light bg-white header-light fixed-top always-fixed">
                <div className="container-lg nav-header-container p-4">
                    <div className="col-auto col-sm-6 col-lg-2 mr-auto pl-lg-0">
                        <a className="navbar-brand" href="#">
                            <img src="./images/logo/logo.svg" data-at2x="./images/logo/logo@2x.png" class="default-logo" alt=""></img>
                            <img src="./images/logo/logo.svg" data-at2x="./images/logo/logo@2x.png" class="alt-logo" alt=""></img>
                            <img src="./images/logo/logo-mob.png" data-at2x="./images/logo/logo-mob@2x.png" class="mobile-logo" alt=""></img>
                        </a>
                    </div>

                    {/*
                        confirmation
                        login
                    */}

                    {
                        (lastSimbol == 'confirmation' || lastSimbol == 'login') &&
                        <div className="col-auto col-lg-2 text-right pr-0 font-size-0">
                            <div className="d-md-inline-block">
                                <Link to="/assistance" className="btn btn-astc">Assistance</Link>
                            </div>
                        </div>
                    }
                
                </div>
            </nav>
        </header>
    </>);
}

export { Header };