import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

/**
 * Прикольно представлены Экшены Юзера
 */
export const userActions = {
    checkOrgCode,
    checkOrgEmail,
    login,
    googleLogin,
    logout,
    emailConfirmation,
    register,
    forgotPassword,
    takeAssistance,
    getAll,
    delete: _delete
};


/**
 * Промежуточный этап проверки Кода Организации
 * 
 * @param {*} user 
 */
function checkOrgCode(orgCode) {
    return dispatch => {
        dispatch(request(orgCode));

        userService.checkOrgCode(orgCode)
            .then(
                orgCode => { 
                    dispatch(success());
                    // history.push('/login');
                    // dispatch(alertActions.success('This is asignable org code'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    // dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(orgCode) { return { type: userConstants.ORGCODE_REQUEST, orgCode } }
    function success(orgCode) { return { type: userConstants.ORGCODE_SUCCESS, orgCode } }
    function failure(error) { return { type: userConstants.ORGCODE_FAILURE, error } }
}


/**
 * Промежуточный этап проверки Почты если этот пользователь логиниться как Комерс
 * 
 * @param {*} user 
 */
function checkOrgEmail(email, orgCode) {
    return dispatch => {
        dispatch(request(orgCode));

        userService.checkOrgEmail(email, orgCode)
            .then(
                orgCode => { 
                    dispatch(success());
                    // history.push('/login');
                    // dispatch(alertActions.success('Email is asignable'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    // dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(orgCode) { return { type: userConstants.ORGEMAIL_REQUEST, orgCode } }
    function success(orgCode) { return { type: userConstants.ORGEMAIL_SUCCESS, orgCode } }
    function failure(error) { return { type: userConstants.ORGEMAIL_FAILURE, error } }
}


/**
 * Экшен первого шага
 * 
 * @param {*} user 
 */
function emailConfirmation(user) {
    
    return (dispatch, getState) => {

        console.log( 'emailConfirmation - getState()', getState().registration.resentCodeAtempt );
        dispatch(changeAtempt( getState().registration.resentCodeAtempt ));

        dispatch(request(user));

        userService.emailConfirmation(user)
            .then(
                response => { 
                    console.log('userService.emailConfirmation(response) - ', response);

                    dispatch(success(user));
                    history.push('/register');
                    // dispatch(alertActions.success('Email confirmation successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.CONFIRMATION_REQUEST, user } }
    function success(user) { return { type: userConstants.CONFIRMATION_SUCCESS, user } }
    function failure(error) { return { type: userConstants.CONFIRMATION_FAILURE, error } }

    function changeAtempt(attempt) { 
        let att = --attempt
        // console.log('changeAtempt------------------------------');
        // debugger;
        return { type: 'changeAtempt', att } 
    }
}





/**
 * Экшен второго шага
 * 
 * @param {*} user 
 */
function register(user) {
    return dispatch => {
        dispatch(request(user));

        console.log(user.confirmationCode);
        user.confirmationCode = user.confirmationCode.replace(/ /g, '');
        // debugger;

        userService.register(user)
            .then(
                user => { 
                    dispatch(success());
                    history.push('/well_us');
                    // dispatch(alertActions.success('Registration successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}




/**
 * Результат возвращаемый этой функцией будет вызван в Танке, где ему 
 * будет передан dispatch
 * 
 * @param {*} username 
 * @param {*} password 
 * @param {*} from 
 */
function login(username, password, from) {
    return dispatch => {
        dispatch(request({ username, password }));

        userService.login(username, password)
            .then(
                res => { 

                    // console.log('res res res res - ', res);

                    // dispatch(alertActions.success('Login successful'));

                    dispatch(success(username));
                    history.push('/well_us');
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}


/**
 * 
 * @param {*} googleAswer 
 */
function googleLogin(googleAswer) {

    // console.log('googleLogin start');



    
    return dispatch => {
        
        // console.log( 'googleAswer?.uc - ', googleAswer?.uc );
        // debugger;

        if (googleAswer?.uc) {
            dispatch( shortGoogleLoginSuccess(googleAswer) );
            history.push('/well_us');
        }
        return {};

        dispatch( request({}) );

        userService.googleLogin(googleAswer)
            .then(
                res => { 

                    console.log('googleLogin res - ', res);

                    // dispatch(alertActions.success('Login successful'));

                    // dispatch(success(user));
                    // history.push('/well_us');
                },
                error => {

                    console.log('googleLogin failed');
                    // dispatch(failure(error.toString()));
                    // dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function shortGoogleLoginSuccess(googleAswer) {
        return { type: 'GOOGLE_LOGIN_SUCCESS', googleAswer }
    }

    function request(data) { return { type: 'GOOGLE_LOGIN_REQUEST', data } }
    function success(user) { return { type: 'GOOGLE_LOGIN_SUCCESS', user } }
    function failure(error) { return { type: 'GOOGLE_LOGIN_FAILURE', error } }
}





/**

 */
function forgotPassword(email) {
    return dispatch => {
        // dispatch(request({ username, password }));

        userService.forgotPassword(email)
            .then(
                res => { 

                    console.log('res forgot password - ', res);

                    // dispatch(alertActions.success('Login successful'));

                    // dispatch(success(user));
                    // history.push(from);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(email) { return { type: userConstants.LOGIN_REQUEST, email } }
    function success(email) { return { type: userConstants.LOGIN_SUCCESS, email } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}


/*
function login(username, password, from) {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                user => { 
                    dispatch(success(user));
                    history.push(from);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

*/



function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}



/**
 * 
 */
function takeAssistance(user) {
    return dispatch => {
        dispatch(request(user));

        userService.takeAssistance(user)
            .then(
                user => { 
                    dispatch(success(user));
                    history.push('/confirmation');
                    dispatch(alertActions.success('We have received your assistance request. We will be in touch with you via email as soon as we can'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.ASSISTANCE_REQUEST, user } }
    function success(user) { return { type: userConstants.ASSISTANCE_SUCCESS, user } }
    function failure(error) { return { type: userConstants.ASSISTANCE_FAILURE, error } }
}







function getAll() {
    return dispatch => {
        dispatch(request());

        userService.getAll()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        userService.delete(id)
            .then(
                user => dispatch(success(id)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: userConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: userConstants.DELETE_FAILURE, id, error } }
}